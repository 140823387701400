@import url('https://fonts.cdnfonts.com/css/poppins');
@import url('https://fonts.cdnfonts.com/css/BadaBoom BB');
@import url('https://fonts.cdnfonts.com/css/hey-comic');
@import url('https://fonts.cdnfonts.com/css/neck-l-sans');
@import url('https://fonts.cdnfonts.com/css/ds-marker-felt');
@import url('https://fonts.cdnfonts.com/css/fredoka-one');

* {
  object-fit: cover;
}

p {
  font-family: 'Poppins', sans-serif;

}


body {
  margin: 0;
  /* font-family: 'Plus Jakarta Sans', sans-serif; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.marker-font {
  font-family: 'DS Marker Felt', sans-serif;

}

.fredoka {
  font-family: 'Fredoka One', sans-serif;

}

.btn-primary {
  background: #ffa509 !important;
  color: white !important;
}

.featured {
  background: url("../src/assets/featured-bg.png") !important;
  background-size: cover !important;

}

.yee {
  background: url("../src/assets/bg.png") !important;
  background-size: cover !important;

}

.roadma {
  background: url("../src/assets/roadmap.png") !important;
}

.second-card {
  background: url("../src/assets/roadmap.svg") !important;
  background-size: cover !important;

}

.ban {
  /* background: url('../assets/'); */
  background-image: url("../src/assets/asset\ 32.png") !important;
  background-size: cover;
}



.heyCom {
  font-family: Hey Comic, sans-serif;
}

.jakarta {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #F5882D;
  --bs-secondary: #4D6CC3;
  --bs-tertiary: #F0B90B;
  --bs-danger: #8f190f;
  --bs-warning: #FF8000;
  --bs-success: #2dac28;
  --bs-black: #000000;
  --bs-white: #FFFFFF;
  --bs-light: #EAEAEA;
  --bs-grey: #8D8080;
  --bs-light-grey: #A6A6A6;
  --bs-light-green: #50B861;
  --bs-sky-blue: #C1DAEA;
  --bs-light-pink: #FFD957;
  --bs-primary-rgb: 245, 136, 45;
  --bs-secondary-rgb: 77, 108, 195;
  --bs-tertiary-rgb: 240, 185, 11;
  --bs-danger-rgb: 143, 25, 15;
  --bs-warning-rgb: 255, 128, 0;
  --bs-success-rgb: 45, 172, 40;
  --bs-light-rgb: 234, 234, 234;
  --bs-grey-rgb: 141, 128, 128;
  --bs-light-grey-rgb: 166, 166, 166;
  --bs-light-green-rgb: 80, 184, 97;
  --bs-sky-blue-rgb: 193, 218, 234;
  --bs-light-pink-rgb: 255, 217, 87;
  --bs-primary-text-emphasis: #623612;
  --bs-secondary-text-emphasis: #1f2b4e;
  --bs-success-text-emphasis: #124510;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #663300;
  --bs-danger-text-emphasis: #390a06;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #fde7d5;
  --bs-secondary-bg-subtle: #dbe2f3;
  --bs-success-bg-subtle: #d5eed4;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #ffe6cc;
  --bs-danger-bg-subtle: #e9d1cf;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #fbcfab;
  --bs-secondary-border-subtle: #b8c4e7;
  --bs-success-border-subtle: #abdea9;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffcc99;
  --bs-danger-border-subtle: #d2a39f;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, .15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.4;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg: #FFFFFF;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(33, 37, 41, .75);
  --bs-secondary-color-rgb: 33, 37, 41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(33, 37, 41, .5);
  --bs-tertiary-color-rgb: 33, 37, 41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: inherit;
  --bs-link-color: #F5882D;
  --bs-link-color-rgb: 245, 136, 45;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #c46d24;
  --bs-link-hover-color-rgb: 196, 109, 36;
  --bs-code-color: #d63384;
  --bs-highlight-color: #212529;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, .175);
  --bs-border-radius: .3rem;
  --bs-border-radius-sm: .4rem;
  --bs-border-radius-lg: .5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
  --bs-box-shadow-sm: 0px 4px 10px rgba(71, 163, 243, .15), 0px 2px 11px rgba(163, 183, 236, .21);
  --bs-box-shadow-lg: 0px 4px 91px rgba(255, 139, 91, .15), 0px 2px 50px rgba(107, 31, 0, .06);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, .075);
  --bs-focus-ring-width: .25rem;
  --bs-focus-ring-opacity: .25;
  --bs-focus-ring-color: rgba(245, 136, 45, .25);
  --bs-form-valid-color: #2dac28;
  --bs-form-valid-border-color: #2dac28;
  --bs-form-invalid-color: #8f190f;
  --bs-form-invalid-border-color: #8f190f
}

*,
*:before,
*:after {
  box-sizing: border-box
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

h5,
h3,
h2,
h1 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
  color: var(--bs-heading-color)
}

h1 {
  font-size: calc(1.375rem + 1.5vw)
}

@media (min-width: 1200px) {
  h1 {
    font-size: 2.5rem
  }
}

h2 {
  font-size: calc(1.325rem + .9vw)
}

@media (min-width: 1200px) {
  h2 {
    font-size: 2rem
  }
}

h3 {
  font-size: calc(1.3rem + .6vw)
}

@media (min-width: 1200px) {
  h3 {
    font-size: 1.75rem
  }
}

h5 {
  font-size: 1.125rem
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

ul {
  padding-left: 2rem
}

ul {
  margin-top: 0;
  margin-bottom: 1rem
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: underline
}

a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb)
}

img,
svg {
  vertical-align: middle
}

label {
  display: inline-block
}

button {
  border-radius: 0
}

button:focus:not(:focus-visible) {
  outline: 0
}

input,
button {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

button {
  text-transform: none
}

button {
  -webkit-appearance: button
}

button:not(:disabled) {
  cursor: pointer
}

.img-fluid {
  max-width: 100%;
  height: auto
}

.container,
.container-fluid {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 576px) {
  .container {
    max-width: 540px
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px
  }
}

@media (min-width: 1025px) {
  .container {
    max-width: 960px
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1320px
  }
}

:root {
  --bs-breakpoint-xxs: 0;
  --bs-breakpoint-mini: 300px;
  --bs-breakpoint-min: 360px;
  --bs-breakpoint-xs: 410px;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 1025px;
  --bs-breakpoint-xl: 1200px;
  --bs-breakpoint-xxl: 1440px
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-.5 * var(--bs-gutter-x));
  margin-left: calc(-.5 * var(--bs-gutter-x))
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * .5);
  padding-left: calc(var(--bs-gutter-x) * .5);
  margin-top: var(--bs-gutter-y)
}

.col-12 {
  flex: 0 0 auto;
  width: 100%
}

@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%
  }
}

@media (min-width: 1025px) {
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }
}

@media (min-width: 1440px) {
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%
  }
}

.form-control {
  display: block;
  width: 100%;
  padding: .875rem 1rem;
  font-family: BadaBoom BB, sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.2;
  color: #fff;
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: 1px solid var(--bs-border-color);
  border-radius: .4rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none
  }
}

.form-control:focus {
  color: #fff;
  background-color: #8d8080;
  border-color: #8d8080;
  outline: 0;
  box-shadow: none
}

.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.2em;
  margin: 0
}

.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0
}

.form-control::placeholder {
  color: #8d8080;
  opacity: 1
}

.form-control:disabled {
  background-color: #8d8080;
  opacity: 1
}

.form-control::file-selector-button {
  padding: .875rem 1rem;
  margin: -.875rem -1rem;
  margin-inline-end: 1rem;
  color: #fff;
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none
  }
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg)
}

.btn {
  --bs-btn-padding-x: 1rem;
  --bs-btn-padding-y: .75rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1rem;
  --bs-btn-font-weight: 400;
  --bs-btn-line-height: 1.4;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 2px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 999px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, .15), 0 1px 1px rgba(0, 0, 0, .075);
  --bs-btn-disabled-opacity: .65;
  --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none
  }
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color)
}

.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-focus-box-shadow)
}

:not(.btn-check)+.btn:active,
.btn:first-child:active {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color)
}

:not(.btn-check)+.btn:active:focus-visible,
.btn:first-child:active:focus-visible {
  box-shadow: var(--bs-btn-focus-box-shadow)
}

.btn:disabled {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity)
}

.btn-secondary {
  --bs-btn-color: #FFFFFF;
  --bs-btn-bg: #4D6CC3;
  --bs-btn-border-color: #4D6CC3;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #415ca6;
  --bs-btn-hover-border-color: #3e569c;
  --bs-btn-focus-shadow-rgb: 104, 130, 204;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #3e569c;
  --bs-btn-active-border-color: #3a5192;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  --bs-btn-disabled-color: #FFFFFF;
  --bs-btn-disabled-bg: #4D6CC3;
  --bs-btn-disabled-border-color: #4D6CC3
}

.progress {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: .75rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #FFFFFF;
  --bs-progress-bar-bg: #F5882D;
  --bs-progress-bar-transition: width .6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius)
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.float-start {
  float: left !important
}

.overflow-hidden {
  overflow: hidden !important
}

.d-inline-block {
  display: inline-block !important
}

.d-block {
  display: block !important
}

.d-flex {
  display: flex !important
}

.d-none {
  display: none !important
}

.position-relative {
  position: relative !important
}

.border-0 {
  border: 0 !important
}

.w-50 {
  width: 50% !important
}

.w-100 {
  width: 100% !important
}

.flex-column {
  flex-direction: column !important
}

.flex-wrap {
  flex-wrap: wrap !important
}

.justify-content-start {
  justify-content: flex-start !important
}

.justify-content-end {
  justify-content: flex-end !important
}

.justify-content-center {
  justify-content: center !important
}

.justify-content-between {
  justify-content: space-between !important
}

.align-items-start {
  align-items: flex-start !important
}

.align-items-center {
  align-items: center !important
}

.align-self-center {
  align-self: center !important
}

.order-1 {
  order: 1 !important
}

.order-2 {
  order: 2 !important
}

.m-0 {
  margin: 0 !important
}

.m-2 {
  margin: .5rem !important
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important
}

.my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important
}

.mt-2 {
  margin-top: .5rem !important
}

.mt-3 {
  margin-top: 1rem !important
}

.mt-4 {
  margin-top: 1.5rem !important
}

.mt-5 {
  margin-top: 3rem !important
}

.me-2 {
  margin-right: .5rem !important
}

.mb-0 {
  margin-bottom: 0 !important
}

.mb-1 {
  margin-bottom: .25rem !important
}

.mb-2 {
  margin-bottom: .5rem !important
}

.mb-3 {
  margin-bottom: 1rem !important
}

.mb-4 {
  margin-bottom: 1.5rem !important
}

.mb-5 {
  margin-bottom: 3rem !important
}

.ms-2 {
  margin-left: .5rem !important
}

.ms-4 {
  margin-left: 1.5rem !important
}

.p-0 {
  padding: 0 !important
}

.p-2 {
  padding: .5rem !important
}

.px-2 {
  padding-right: .5rem !important;
  padding-left: .5rem !important
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important
}

.pt-2 {
  padding-top: .5rem !important
}

.pb-1 {
  padding-bottom: .25rem !important
}

.pb-3 {
  padding-bottom: 1rem !important
}

.ps-1 {
  padding-left: .25rem !important
}

.ps-2 {
  padding-left: .5rem !important
}

.ps-5 {
  padding-left: 3rem !important
}

.gap-2 {
  gap: .5rem !important
}

.gap-3 {
  gap: 1rem !important
}

.gap-4 {
  gap: 1.5rem !important
}

.fs-6 {
  font-size: 1rem !important
}

.fs-7 {
  font-size: .875rem !important
}

.fw-medium {
  font-weight: 500 !important
}

.fw-semibold {
  font-weight: 600 !important
}

.text-center {
  text-align: center !important
}

.text-decoration-underline {
  text-decoration: underline !important
}

.text-uppercase {
  text-transform: uppercase !important
}

.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important
}

.text-tertiary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-rgb), var(--bs-text-opacity)) !important
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important
}

.bg-light-green {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-green-rgb), var(--bs-bg-opacity)) !important
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important
}

@media (min-width: 576px) {
  .d-sm-block {
    display: block !important
  }

  .d-sm-none {
    display: none !important
  }

  .justify-content-sm-start {
    justify-content: flex-start !important
  }

  .mt-sm-5 {
    margin-top: 3rem !important
  }

  .pt-sm-3 {
    padding-top: 1rem !important
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important
  }

  .gap-sm-3 {
    gap: 1rem !important
  }
}

@media (min-width: 768px) {
  .d-md-block {
    display: block !important
  }

  .d-md-none {
    display: none !important
  }

  .mt-md-0 {
    margin-top: 0 !important
  }

  .pe-md-2 {
    padding-right: .5rem !important
  }

  .ps-md-2 {
    padding-left: .5rem !important
  }
}

@media (min-width: 1025px) {
  .d-lg-block {
    display: block !important
  }

  .d-lg-none {
    display: none !important
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important
  }

  .order-lg-1 {
    order: 1 !important
  }

  .order-lg-2 {
    order: 2 !important
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important
  }
}

.font-13 {
  font-size: 13px !important
}

.font-14 {
  font-size: 14px !important
}

.font-15 {
  font-size: 15px !important
}

.font-16 {
  font-size: 16px !important
}

.font-18 {
  font-size: 18px !important
}

.font-20 {
  font-size: 20px !important
}

.font-22 {
  font-size: 22px !important
}

.font-24 {
  font-size: 24px !important
}

.font-30 {
  font-size: 30px !important
}

.font-32 {
  font-size: 32px !important
}

.font-44 {
  font-size: 44px !important
}

@media (min-width: 1025px) {
  .font-lg-16 {
    font-size: 16px !important
  }

  .font-lg-18 {
    font-size: 18px !important
  }

  .font-lg-24 {
    font-size: 24px !important
  }

  .font-lg-36 {
    font-size: 36px !important
  }

  .font-lg-48 {
    font-size: 48px !important
  }
}

.btn {
  line-height: 1;
  transition: all .25s ease-in-out;
  font-weight: 500
}

ul {
  padding-left: 1rem
}

html,
body {
  margin: 0;
  padding: 0
}

body {
  background: #4d6cc3;
  font-family: Hey Comic, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  color: #000
}

a {
  transition: all .25s ease-in-out;
  text-decoration: none;
  color: #fff
}

a:focus {
  outline: none
}

a:hover {
  text-decoration: none !important
}

.container {
  max-width: 1510px
}

.font-family-primary {
  font-family: BadaBoom BB, sans-serif
}

.font-family-secondary {
  font-family: Hey Comic, sans-serif
}

.title {
  height: 45px
}

.title .text {
  paint-order: stroke fill
}

.section-title {
  width: max-content;
  font-size: 54px;
  background: #c1daea;
  border: 4px solid #000000;
  color: #f0b90b;
  -webkit-text-stroke: 8px black;
  font-weight: 500;
  padding: 5px 30px;
  transform: rotate(-3deg);
  font-family: BadaBoom BB, sans-serif;
  paint-order: stroke fill
}

@media screen and (max-width: 576px) {
  .section-title {
    font-size: 30px;
    border-width: 3px;
    -webkit-text-stroke: 6px black
  }
}

.btn {
  font-family: BadaBoom BB, sans-serif;
  font-size: 28px;
  font-weight: 600;
  border-radius: 0;
  border: none;
  padding: 2px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 120px
}

@media screen and (max-width: 576px) {
  .btn {
    font-size: 14px;
    min-width: 120px
  }
}

.btn-secondary {
  background-color: #f5882d;
  border: 4px solid #000000;
  color: #fff;
  padding: 2px 15px
}

.btn-secondary .text {
  paint-order: stroke fill;
  font-weight: 500
}

.btn-secondary:hover {
  border: 4px solid #000000 !important
}

.font-family-tertiary {
  font-family: 'Poppins', sans-serif;
  font-weight: 700
}

.bada {
  font-family: BadaBoom BB, sans-serif;
}

.cursor-pointer {
  cursor: pointer !important
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg)
  }
}

.shimmer {
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #f5882d linear-gradient(to right, #f5882d33, #4d6cc380 50%, #f5882d33 75%);
  background-size: 1000px 100%;
  color: transparent !important;
  border-color: transparent !important;
  border-radius: 4px
}

.shimmer span,
.shimmer img {
  opacity: 0
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0
  }

  to {
    background-position: 100% 0
  }
}

@keyframes shimmer {
  0% {
    background-position: -1200px 0
  }

  to {
    background-position: 1200px 0
  }
}

.dashTitle {
  position: relative;
  letter-spacing: 1.5px;
  font-size: 13px
}

.dashTitle:before,
.dashTitle:after {
  content: "";
  width: 20%;
  height: 1px;
  background: #000;
  position: absolute;
  left: 0;
  top: 50%
}

@media screen and (max-width: 576px) {

  .dashTitle:before,
  .dashTitle:after {
    width: 20%
  }
}

.dashTitle:after {
  right: 0;
  left: auto
}

.tab-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}

.tab-container .btn {
  min-width: 130px;
  height: 45px;
  padding: 6px;
  border-radius: 30px;
  border: 2px solid #000000;
  color: #000;
  background: transparent;
  font-weight: 400
}

.tab-container .btn.selected {
  background: #f5882d;
  color: #fff;
  font-weight: 500
}

@media screen and (max-width: 576px) {
  .tab-container .btn {
    min-width: 100px
  }
}

.header[_ngcontent-ng-c4253741092] {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 5;
  padding: 0
}

.header.scrolled[_ngcontent-ng-c4253741092] {
  top: 0;
  background: #f5882d;
  box-shadow: 0 4px 8px #0000, 0 6px 20px #0000001f
}

@media screen and (max-width: 576px) {
  .header[_ngcontent-ng-c4253741092] {
    top: 0;
    padding: 12px
  }
}

.header[_ngcontent-ng-c4253741092] a[_ngcontent-ng-c4253741092] {
  padding: 10px 0;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 20px;
  margin: 0 7px;
  -webkit-text-stroke: 3px black;
  letter-spacing: .2px;
  paint-order: stroke fill
}

@media (min-width: 1500px) and (max-width: 2300px) {
  .header[_ngcontent-ng-c4253741092] a[_ngcontent-ng-c4253741092] {
    font-size: 28px
  }
}

.header[_ngcontent-ng-c4253741092] a[_ngcontent-ng-c4253741092]:first-child {
  margin-left: 0
}

.header[_ngcontent-ng-c4253741092] a.active[_ngcontent-ng-c4253741092],
.header[_ngcontent-ng-c4253741092] a[_ngcontent-ng-c4253741092]:hover {
  color: #50b861
}

@media only screen and (min-width: 577px) and (max-width: 1024px) {
  .header[_ngcontent-ng-c4253741092] a[_ngcontent-ng-c4253741092] {
    padding: 10px;
    margin: 0
  }
}

@media screen and (max-width: 576px) {
  .header[_ngcontent-ng-c4253741092] a[_ngcontent-ng-c4253741092] {
    margin: 0
  }
}

.header[_ngcontent-ng-c4253741092] .btn-primary[_ngcontent-ng-c4253741092] {
  width: 153px;
  font-weight: 400
}

.header[_ngcontent-ng-c4253741092] .section-title[_ngcontent-ng-c4253741092] {
  height: 30px
}

.header[_ngcontent-ng-c4253741092] .head[_ngcontent-ng-c4253741092] {
  -webkit-text-stroke: 4px white;
  paint-order: stroke fill;
  color: #000
}

.header[_ngcontent-ng-c4253741092] .cursor-auto[_ngcontent-ng-c4253741092] {
  cursor: auto !important
}

.header[_ngcontent-ng-c4253741092] .language[_ngcontent-ng-c4253741092] {
  text-decoration: none;
  letter-spacing: .02em;
  text-transform: capitalize;
  font-family: BadaBoom BB, sans-serif;
  font-weight: 400;
  padding: .3rem 1rem;
  border-radius: 16px;
  -webkit-text-stroke: 1px black
}

.header[_ngcontent-ng-c4253741092] .menu-dropdown[_ngcontent-ng-c4253741092] {
  padding: 10px 0;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  text-transform: capitalize;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 14px;
  width: auto
}

.header[_ngcontent-ng-c4253741092] .menu-dropdown.active[_ngcontent-ng-c4253741092],
.header[_ngcontent-ng-c4253741092] .menu-dropdown[_ngcontent-ng-c4253741092]:hover {
  color: #4d6cc3
}

.header[_ngcontent-ng-c4253741092] .small-btn[_ngcontent-ng-c4253741092] {
  min-height: unset;
  height: 42px
}

.header[_ngcontent-ng-c4253741092] .menu-btn[_ngcontent-ng-c4253741092] {
  cursor: pointer;
  display: flex;
  padding: 0 10px;
  border-radius: 8px
}

.header[_ngcontent-ng-c4253741092] .menu-btn.menu-open[_ngcontent-ng-c4253741092] .line1[_ngcontent-ng-c4253741092],
.header[_ngcontent-ng-c4253741092] .menu-btn.menu-open[_ngcontent-ng-c4253741092] .line3[_ngcontent-ng-c4253741092] {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134
}

.header[_ngcontent-ng-c4253741092] .menu-btn.menu-open[_ngcontent-ng-c4253741092] .line2[_ngcontent-ng-c4253741092] {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30
}

.header[_ngcontent-ng-c4253741092] .menu-overlay[_ngcontent-ng-c4253741092] {
  background: #000000bf;
  position: fixed;
  width: 100%;
  height: calc(100vh - 80px);
  top: 80px;
  left: 0;
  display: flex;
  justify-content: flex-end
}

.header[_ngcontent-ng-c4253741092] .menu-overlay[_ngcontent-ng-c4253741092] .sub-menu[_ngcontent-ng-c4253741092] {
  height: 100vh;
  background: #f5882d;
  width: 100%;
  padding: 20px;
  position: relative;
  transition: right .5s;
  right: -100%;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden
}

.header[_ngcontent-ng-c4253741092] .menu-overlay[_ngcontent-ng-c4253741092] .sub-menu[_ngcontent-ng-c4253741092] a[_ngcontent-ng-c4253741092] {
  font-size: 30px !important;
  opacity: 0;
  font-weight: 800;
  width: 100%;
  margin-bottom: 20px;
  padding: 5px;
  letter-spacing: .02em;
  text-transform: capitalize;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, .3607843137)
}

.header[_ngcontent-ng-c4253741092] .menu-overlay[_ngcontent-ng-c4253741092] .sub-menu.open[_ngcontent-ng-c4253741092] {
  right: 0
}

.header[_ngcontent-ng-c4253741092] .menu-overlay[_ngcontent-ng-c4253741092] .sub-menu.open[_ngcontent-ng-c4253741092] a[_ngcontent-ng-c4253741092] {
  opacity: 1;
  color: #fff
}

.header[_ngcontent-ng-c4253741092] .menu-overlay[_ngcontent-ng-c4253741092] .sub-menu.open[_ngcontent-ng-c4253741092] a.active[_ngcontent-ng-c4253741092],
.header[_ngcontent-ng-c4253741092] .menu-overlay[_ngcontent-ng-c4253741092] .sub-menu.open[_ngcontent-ng-c4253741092] a[_ngcontent-ng-c4253741092]:hover {
  color: #50b861
}

.header[_ngcontent-ng-c4253741092] .menu-overlay[_ngcontent-ng-c4253741092] .sub-menu[_ngcontent-ng-c4253741092] .language[_ngcontent-ng-c4253741092] {
  width: 100% !important;
  padding: 4px 10px;
  margin: 0;
  border: 3px solid #000000;
  border-radius: 0;
  text-decoration: none;
  letter-spacing: .02em;
  text-transform: uppercase;
  font-weight: 400
}

.header[_ngcontent-ng-c4253741092] .menu-overlay[_ngcontent-ng-c4253741092] .sub-menu[_ngcontent-ng-c4253741092] .language[_ngcontent-ng-c4253741092] .dropdown-toggle[_ngcontent-ng-c4253741092]:after {
  position: absolute;
  right: 12px
}

@media screen and (max-width: 1024px) {
  .header[_ngcontent-ng-c4253741092] .menu-overlay[_ngcontent-ng-c4253741092] .sub-menu[_ngcontent-ng-c4253741092] .language[_ngcontent-ng-c4253741092] .dropdown-menu.show[_ngcontent-ng-c4253741092] {
    min-width: 100%
  }
}

.social-wrapper[_ngcontent-ng-c4253741092] a[_ngcontent-ng-c4253741092] {
  border-bottom: none !important;
  width: auto !important
}

.footer[_ngcontent-ng-c3641084702] {
  position: relative;
  padding: 0 0 30px;
  margin-top: -50px
}

.footer[_ngcontent-ng-c3641084702] p[_ngcontent-ng-c3641084702] {
  color: #ffffff80
}

.footer[_ngcontent-ng-c3641084702] .disclaimer[_ngcontent-ng-c3641084702] {
  font-family: Hey Comic, sans-serif
}

.footer[_ngcontent-ng-c3641084702] a[_ngcontent-ng-c3641084702] {
  font-family: BadaBoom BB, sans-serif;
  -webkit-text-stroke: 2px #000000;
  paint-order: stroke fill
}

.footer[_ngcontent-ng-c3641084702] img[_ngcontent-ng-c3641084702] {
  position: relative;
  z-index: 1
}

@media screen and (max-width: 768px) {
  .footer[_ngcontent-ng-c3641084702] img[_ngcontent-ng-c3641084702] {
    width: 60px;
    height: 60px
  }
}

.banner[_ngcontent-ng-c899385726] {
  position: relative;
  padding: 130px 0;
  background: url(../src/asset/images/pngs/banner-desktop.png) no-repeat;
  background-size: 100%;
  min-height: 900px
}

.backGround {
  background: url(../src/asset/images/pngs/banner-desktop.png) no-repeat;
  background-size: 100vw;
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] {
    background: url(../src/asset/images/pngs/mobile-banner.png) no-repeat 50% -3%;
    background-size: 100vw;
    padding: 96px 0
  }

  .backGround {
    background: url(../src/asset/images/pngs/mobile-banner.png) no-repeat 50% -3%;
    /* background-size: 100vw; */
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {
  .banner[_ngcontent-ng-c899385726] {
    min-height: 950px
  }
}

@media (min-width: 1400px) and (max-width: 1500px) {
  .banner[_ngcontent-ng-c899385726] {
    min-height: 1000px
  }
}

@media (min-width: 1500px) and (max-width: 2300px) {
  .banner[_ngcontent-ng-c899385726] {
    background: url(../src/asset/images/pngs/banner-desktop-lg.png) no-repeat;
    min-height: 940px;
    background-size: contain;
    padding: 140px 0 80px
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] {
  position: relative
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] {
    padding: 0
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .section-title[_ngcontent-ng-c899385726] {
  transform: unset;
  padding: unset
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .section-title[_ngcontent-ng-c899385726] {
    font-size: 38px !important;
    max-width: 380px;
    text-align: left !important;
    rotate: -5deg;
    width: 100%
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] {
  background: transparent;
  position: relative;
  display: block;
  z-index: 2
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] {
  transform: rotate(-3deg);
  padding: 35px;
  width: 85%;
  min-width: 650px;
  min-height: 400px;
  margin-left: -8px;
  margin-top: -16px
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] {
    transform: none;
    width: 120%;
    padding: 53px 60px 90px 90px;
    min-width: 100%;
    min-height: 550px;
    margin-left: -10%;
    margin-top: 16px
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .wrap[_ngcontent-ng-c899385726] {
  gap: 8px;
  display: flex;
  align-items: center;
  max-width: 500px;
  flex-wrap: wrap
}

@media (min-width: 1500px) and (max-width: 2300px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .wrap[_ngcontent-ng-c899385726] {
    max-width: 800px
  }
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .wrap[_ngcontent-ng-c899385726] {
    max-width: max-content;
    rotate: -5deg
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .wrap[_ngcontent-ng-c899385726] .text-box[_ngcontent-ng-c899385726] {
  background: #2045ca;
  border-radius: 50px;
  padding: 10px 15px
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .wrap[_ngcontent-ng-c899385726] .text-box[_ngcontent-ng-c899385726] {
    max-width: max-content;
    margin-top: 10px
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .wrap[_ngcontent-ng-c899385726] .text-box[_ngcontent-ng-c899385726] .circle-icon[_ngcontent-ng-c899385726] {
  border-radius: 50%;
  box-shadow: 0 0 19.51px #f5882d
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .wrap[_ngcontent-ng-c899385726] .text-box[_ngcontent-ng-c899385726] span[_ngcontent-ng-c899385726] {
  -webkit-text-stroke: 2px #000000;
  font-family: Hey Comic, sans-serif;
  text-transform: uppercase;
  paint-order: stroke;
  font-weight: 500
}

@media (min-width: 1500px) and (max-width: 2300px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .wrap[_ngcontent-ng-c899385726] .text-box[_ngcontent-ng-c899385726] span[_ngcontent-ng-c899385726] {
    font-size: 22px !important
  }
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .wrap[_ngcontent-ng-c899385726] .text-box[_ngcontent-ng-c899385726] span[_ngcontent-ng-c899385726] {
    font-size: 13px !important
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726]:after {
  content: "";
  position: absolute;
  background: #c1daea
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .started-btn[_ngcontent-ng-c899385726] {
  width: max-content;
  font-size: 55px;
  background: #f5882d;
  border: 4px solid #000000;
  box-shadow: 0 0 32px #f5882d82;
  color: #fff;
  -webkit-text-stroke: 7px black;
  font-weight: 500;
  padding: 0 117px 0 35px;
  paint-order: stroke fill
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .started-btn[_ngcontent-ng-c899385726] {
    max-width: 100%;
    font-size: 30px;
    padding: 5px 45px;
    border: 3px solid #000000;
    -webkit-text-stroke: 5px black;
    rotate: -5deg;
    margin-left: 15px
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .banner-desc[_ngcontent-ng-c899385726] {
  max-width: 524px;
  font-weight: 500;
  color: #000
}

@media (min-width: 1500px) and (max-width: 2300px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .banner-desc[_ngcontent-ng-c899385726] {
    font-size: 25px !important;
    max-width: 645px
  }
}

@media (min-width: 1500px) and (max-width: 2300px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .banner-desc[_ngcontent-ng-c899385726] span[_ngcontent-ng-c899385726] {
    font-size: 25px !important
  }
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726] .banner-desc[_ngcontent-ng-c899385726] {
    text-align: left;
    max-width: 100%;
    rotate: -5deg
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726],
.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726]:after {
  clip-path: polygon(0 0, 100% 0%, 70% 100%, 0% 100%);
  inset: 6px;
  z-index: -1
}

@media screen and (max-width: 576px) {

  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726],
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .content[_ngcontent-ng-c899385726]:after {
    clip-path: polygon(0 5%, 100% 0%, 100% 100%, 0% 100%);
    width: auto
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .contentBg[_ngcontent-ng-c899385726] {
  background: #000
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .content-outer[_ngcontent-ng-c899385726] .contentBg[_ngcontent-ng-c899385726] {
    transform: translate(6px)
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .content-wrapper[_ngcontent-ng-c899385726] .vote-gif[_ngcontent-ng-c899385726] {
  position: absolute;
  right: 0;
  max-width: 228px;
  bottom: -22%;
  z-index: 0
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .social[_ngcontent-ng-c899385726] {
  padding-top: 50px;
  padding-left: 50px
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .social[_ngcontent-ng-c899385726] .image-icon[_ngcontent-ng-c899385726] {
  display: block;
  width: 69px;
  height: 69px
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .social[_ngcontent-ng-c899385726] .image-icon[_ngcontent-ng-c899385726] svg[_ngcontent-ng-c899385726] {
  height: 100%;
  width: 100%
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .social[_ngcontent-ng-c899385726] {
    flex-wrap: wrap;
    margin-top: -100px;
    padding-left: 10px;
    z-index: 2;
    position: absolute;
    width: 180px;
    justify-content: space-between !important;
    gap: 15px
  }

  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .social[_ngcontent-ng-c899385726] a[_ngcontent-ng-c899385726] {
    margin-left: 1rem !important
  }

  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .social[_ngcontent-ng-c899385726] .image-icon[_ngcontent-ng-c899385726] {
    width: 60px;
    height: 60px
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .social[_ngcontent-ng-c899385726] .social-name[_ngcontent-ng-c899385726] {
  position: absolute;
  bottom: -40px;
  left: 50%;
  font-family: BadaBoom BB, sans-serif;
  padding: 3px 15px;
  background-color: #f5882d;
  color: #fff;
  -webkit-text-stroke: 5px #000000;
  font-weight: 500;
  letter-spacing: 2px;
  paint-order: stroke fill;
  border: 3px solid #000000;
  transform: translate(-50%) rotate(-3deg)
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .leftMargin[_ngcontent-ng-c899385726] {
  position: relative;
  margin-left: -20px
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .leftMargin[_ngcontent-ng-c899385726] .hero-img[_ngcontent-ng-c899385726] {
  position: absolute;
  top: 24%;
  right: -10%;
  max-width: 552px;
  z-index: 2
}

@media screen and (max-width: 1700px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .leftMargin[_ngcontent-ng-c899385726] .hero-img[_ngcontent-ng-c899385726] {
    max-width: 570px;
    right: -10%
  }
}

@media screen and (max-width: 1399px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .leftMargin[_ngcontent-ng-c899385726] .hero-img[_ngcontent-ng-c899385726] {
    max-width: 486px;
    right: -19%
  }
}

@media screen and (max-width: 1024px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .leftMargin[_ngcontent-ng-c899385726] .hero-img[_ngcontent-ng-c899385726] {
    max-width: 580px;
    right: -10%
  }
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .leftSec[_ngcontent-ng-c899385726] .leftMargin[_ngcontent-ng-c899385726] .hero-img[_ngcontent-ng-c899385726] {
    display: none
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] {
  position: relative
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .section-title[_ngcontent-ng-c899385726] {
  transform: unset;
  padding: unset;
  background: none;
  -webkit-text-stroke: 5px black
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] {
  width: 100%;
  max-width: 450px;
  z-index: 1;
  margin-left: auto;
  position: relative;
  border: 8px solid #000000;
  background: #fefefe96;
  border-radius: 0;
  padding: 10px 25px;
  -webkit-backdrop-filter: blur(21px);
  backdrop-filter: blur(21px)
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] {
    margin: 0 auto;
    padding: 18px 15px
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .counter[_ngcontent-ng-c899385726] {
  background: #4d6cc3;
  border: 5px solid #000000;
  font-family: BadaBoom BB, sans-serif
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .counter[_ngcontent-ng-c899385726] .time-card[_ngcontent-ng-c899385726] {
  min-width: 78px;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .counter[_ngcontent-ng-c899385726] .time-card[_ngcontent-ng-c899385726] .value[_ngcontent-ng-c899385726] {
  font-size: 35px;
  font-weight: 800;
  line-height: 1;
  -webkit-text-stroke: 6px black;
  paint-order: stroke fill
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .counter[_ngcontent-ng-c899385726] .time-card[_ngcontent-ng-c899385726] .indicator[_ngcontent-ng-c899385726] {
  font-size: 18px;
  line-height: 20px;
  font-weight: 800;
  -webkit-text-stroke: 4px black;
  paint-order: stroke fill
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .one[_ngcontent-ng-c899385726] {
  color: #ffd957
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .two[_ngcontent-ng-c899385726] {
  color: #ffa22c
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .three[_ngcontent-ng-c899385726] {
  color: #48c95d
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .four[_ngcontent-ng-c899385726] {
  color: #68bef6
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .btn-secondary[_ngcontent-ng-c899385726] .text[_ngcontent-ng-c899385726] {
  transform: unset;
  width: 100% !important;
  padding: 10px 0;
  color: #fff;
  -webkit-text-stroke: 3px black;
  font-size: 19px !important
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .btn-secondary[_ngcontent-ng-c899385726] .text[_ngcontent-ng-c899385726] {
    font-size: 20px !important
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .progress[_ngcontent-ng-c899385726] {
  width: 90%;
  border-radius: 18px;
  height: 24px;
  border: 2px solid #000000;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  background-color: #4961a5
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .progress[_ngcontent-ng-c899385726] {
    width: 100%
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .progress[_ngcontent-ng-c899385726] .status[_ngcontent-ng-c899385726] {
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  line-height: 22px;
  z-index: 1;
  color: #fff
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .progress[_ngcontent-ng-c899385726] .bar[_ngcontent-ng-c899385726] {
  border-radius: 20px;
  background: #f5882d
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .swapArea[_ngcontent-ng-c899385726] {
  position: relative
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .swapArea.cover[_ngcontent-ng-c899385726]:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #4d6cc300;
  z-index: 1;
  cursor: busy
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .sold-text[_ngcontent-ng-c899385726] .title[_ngcontent-ng-c899385726] {
  height: 45px
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .poweredByIcon[_ngcontent-ng-c899385726] {
  height: 16px
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .whitelist[_ngcontent-ng-c899385726] {
  margin: 15px 0 0
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726] .whitelist[_ngcontent-ng-c899385726] img[_ngcontent-ng-c899385726] {
  display: block;
  margin: 0 auto 15px;
  max-width: 105px
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] .walletBox[_ngcontent-ng-c899385726]:after {
    left: unset;
    right: -17px;
    top: -22px;
    width: 37px;
    height: 44px
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .rightSec[_ngcontent-ng-c899385726] a[_ngcontent-ng-c899385726] {
  color: #eaeaea;
  max-width: 500px
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .reward-wrap[_ngcontent-ng-c899385726] {
  background: #c1daea;
  width: max-content;
  padding: 5px 19px;
  transform: rotate(-3deg);
  border-top: 6px solid #000000;
  border-left: 6px solid #000000;
  border-right: 6px solid #000000;
  z-index: 3;
  position: relative;
  display: flex;
  margin-left: 40px
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .reward-wrap[_ngcontent-ng-c899385726]:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #c1daea;
  bottom: -2px;
  left: 0
}

@media screen and (max-width: 576px) {
  .banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .reward-wrap[_ngcontent-ng-c899385726] {
    transform: rotate(0);
    margin: 0 auto
  }
}

.banner[_ngcontent-ng-c899385726] .bannerSec[_ngcontent-ng-c899385726] .reward-wrap[_ngcontent-ng-c899385726] .reward[_ngcontent-ng-c899385726] {
  color: #fff;
  -webkit-text-stroke: 6px black;
  paint-order: stroke fill;
  font-weight: 500
}

.about[_ngcontent-ng-c3646874086] {
  position: relative;
  padding-top: 130px;
  background: url(../src/asset/images/svg-icons/about-bg.svg) no-repeat center right/contain
}

@media (max-width: 1500px) {
  .about[_ngcontent-ng-c3646874086] {
    margin-top: -50px
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {
  .about[_ngcontent-ng-c3646874086] {
    padding-top: 100px
  }
}

@media screen and (max-width: 768px) {
  .about[_ngcontent-ng-c3646874086] {
    padding: 100px 0;
    overflow: hidden
  }
}

@media screen and (max-width: 390px) {
  .about[_ngcontent-ng-c3646874086] {
    padding: 120px 0
  }
}

@media screen and (max-width: 768px) {
  .about[_ngcontent-ng-c3646874086] .container[_ngcontent-ng-c3646874086] {
    padding: 0 !important
  }
}

.about[_ngcontent-ng-c3646874086] .block-wrap[_ngcontent-ng-c3646874086] {
  position: absolute;
  left: 0;
  right: 0;
  top: -90px;
  text-align: center
}

@media screen and (max-width: 576px) {
  .about[_ngcontent-ng-c3646874086] .block-wrap[_ngcontent-ng-c3646874086] {
    text-align: left;
    position: relative;
    left: 15px;
    top: 0
  }
}

.about[_ngcontent-ng-c3646874086] .block-wrap[_ngcontent-ng-c3646874086] video[_ngcontent-ng-c3646874086] {
  transform: rotate(-3deg);
  border: 5px solid #f27c28;
  border-radius: 7px;
  box-shadow: 0 0 10px 10px #00000036
}

@media screen and (max-width: 1399px) {
  .about[_ngcontent-ng-c3646874086] .block-wrap[_ngcontent-ng-c3646874086] video[_ngcontent-ng-c3646874086] {
    width: 320px
  }
}

@media screen and (max-width: 576px) {
  .about[_ngcontent-ng-c3646874086] .block-wrap[_ngcontent-ng-c3646874086] video[_ngcontent-ng-c3646874086] {
    width: 277px
  }
}

.about[_ngcontent-ng-c3646874086] .block-wrap[_ngcontent-ng-c3646874086] .started-explor-btn[_ngcontent-ng-c3646874086] {
  font-size: 27px;
  margin: auto;
  position: relative;
  top: -52px;
  left: 65px;
  padding: 10px 15px;
  z-index: 1
}

@media screen and (max-width: 576px) {
  .about[_ngcontent-ng-c3646874086] .block-wrap[_ngcontent-ng-c3646874086] .started-explor-btn[_ngcontent-ng-c3646874086] {
    font-size: 22px;
    width: auto;
    display: inline-block !important
  }
}

.about[_ngcontent-ng-c3646874086] .about-wrapper[_ngcontent-ng-c3646874086] {
  background: url(../src/asset/images/svg-icons/about-img.svg) no-repeat bottom right/contain;
  position: relative
}

@media (max-width: 1450px) {
  .about[_ngcontent-ng-c3646874086] .about-wrapper[_ngcontent-ng-c3646874086] {
    background-size: 100%
  }
}

@media screen and (max-width: 1024px) {
  .about[_ngcontent-ng-c3646874086] .about-wrapper[_ngcontent-ng-c3646874086] {
    background-size: unset
  }
}

@media screen and (max-width: 576px) {
  .about[_ngcontent-ng-c3646874086] .about-wrapper[_ngcontent-ng-c3646874086] {
    margin-top: 80px !important;
    background: none
  }
}

.about[_ngcontent-ng-c3646874086] .box[_ngcontent-ng-c3646874086] {
  position: relative;
  border: 6px solid #000000;
  background: #000;
  clip-path: polygon(0% 0%, 98% 0%, -19% 190%, 0% 100%);
  max-width: 99%
}

@media (max-width: 1450px) {
  .about[_ngcontent-ng-c3646874086] .box[_ngcontent-ng-c3646874086] {
    clip-path: polygon(0% 0%, 101% 0%, -19% 190%, 0% 100%)
  }
}

@media screen and (max-width: 576px) {
  .about[_ngcontent-ng-c3646874086] .box[_ngcontent-ng-c3646874086] {
    max-width: 105%;
    border-left-width: 0;
    border-right-width: 0;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 72%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 72%, 0% 100%)
  }
}

.about[_ngcontent-ng-c3646874086] .box[_ngcontent-ng-c3646874086] .inside[_ngcontent-ng-c3646874086] {
  padding: 40px;
  background: #c1daea;
  -webkit-clip-path: polygon(0% 0%, 97% 0%, -19% 190%, 0% 100%);
  clip-path: polygon(0% 0%, 97% 0%, -19% 190%, 0% 100%);
  min-height: 570px
}

@media (max-width: 1450px) {
  .about[_ngcontent-ng-c3646874086] .box[_ngcontent-ng-c3646874086] .inside[_ngcontent-ng-c3646874086] {
    clip-path: polygon(0% 0%, 100% 0%, -19% 190%, 0% 100%)
  }
}

@media screen and (max-width: 576px) {
  .about[_ngcontent-ng-c3646874086] .box[_ngcontent-ng-c3646874086] .inside[_ngcontent-ng-c3646874086] {
    padding: 40px 20px 250px 30px;
    background: #e0e0e0;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 72%, 0% 100%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 72%, 0% 100%)
  }
}

.about[_ngcontent-ng-c3646874086] .box[_ngcontent-ng-c3646874086] .about-info[_ngcontent-ng-c3646874086] {
  max-width: 53%
}

@media screen and (max-width: 768px) {
  .about[_ngcontent-ng-c3646874086] .box[_ngcontent-ng-c3646874086] .about-info[_ngcontent-ng-c3646874086] {
    max-width: 100%
  }
}

.about[_ngcontent-ng-c3646874086] .box[_ngcontent-ng-c3646874086] .about-info[_ngcontent-ng-c3646874086] p[_ngcontent-ng-c3646874086] {
  font-size: 22px;
  margin-bottom: 18px
}

@media screen and (max-width: 576px) {
  .about[_ngcontent-ng-c3646874086] .box[_ngcontent-ng-c3646874086] .about-info[_ngcontent-ng-c3646874086] p[_ngcontent-ng-c3646874086] {
    font-size: 17px
  }
}

.about[_ngcontent-ng-c3646874086] .started-btn[_ngcontent-ng-c3646874086] {
  width: max-content;
  font-size: 54px;
  background: #c1daea;
  border: 4px solid #000000;
  color: #fff;
  -webkit-text-stroke: 4px black;
  paint-order: stroke fill;
  font-weight: 500;
  padding: 8px 25px;
  color: #f0b90b;
  transform: rotate(-3deg)
}

@media screen and (max-width: 576px) {
  .about[_ngcontent-ng-c3646874086] .started-btn[_ngcontent-ng-c3646874086] {
    font-size: 30px
  }
}

.about[_ngcontent-ng-c3646874086] .started-explor-btn[_ngcontent-ng-c3646874086] {
  box-shadow: 0 0 32px #f5882d82;
  color: #fff;
  font-size: 26px
}

@media screen and (max-width: 576px) {
  .about[_ngcontent-ng-c3646874086] .started-explor-btn[_ngcontent-ng-c3646874086] {
    font-size: 24px;
    -webkit-text-stroke: 5px black;
    justify-content: center;
    width: 100%
  }
}

.about[_ngcontent-ng-c3646874086] .king-img[_ngcontent-ng-c3646874086] {
  position: absolute;
  right: 20px;
  bottom: -45px;
  max-width: 700px
}

@media screen and (max-width: 768px) {
  .about[_ngcontent-ng-c3646874086] .king-img[_ngcontent-ng-c3646874086] {
    max-width: 500px;
    right: 0;
    bottom: -30px
  }
}

@media screen and (max-width: 576px) {
  .about[_ngcontent-ng-c3646874086] .king-img[_ngcontent-ng-c3646874086] {
    bottom: -30px;
    left: 30%;
    max-width: 431px;
    transform: translate(-50%)
  }
}

.about[_ngcontent-ng-c3646874086] .mobile-bottom-container[_ngcontent-ng-c3646874086] {
  background-color: #000;
  height: 340px;
  clip-path: polygon(0% 84%, 100% 4%, 100% 100%, 0% 100%);
  margin-top: -230px;
  z-index: -1;
  position: relative
}

.about[_ngcontent-ng-c3646874086] .mobile-bottom-container[_ngcontent-ng-c3646874086] .mobile-bottom-bg[_ngcontent-ng-c3646874086] {
  background: url(../src/asset/images/svg-icons/about-img.svg) no-repeat bottom right/contain;
  height: 100%;
  background-size: 170%;
  background-color: #000;
  clip-path: polygon(0% 86%, 100% 6%, 100% 100%, 0% 100%);
  margin-top: -213px;
  z-index: -1;
  position: relative
}

.about[_ngcontent-ng-c3646874086] .reward-text[_ngcontent-ng-c3646874086] {
  -webkit-text-stroke: 5px black;
  paint-order: stroke fill;
  padding: 0 15px;
  color: #f5882d;
  font-size: 35px !important;
  font-family: BadaBoom BB, sans-serif;
  transform: rotate(-3deg)
}

@media screen and (max-width: 576px) {
  .about[_ngcontent-ng-c3646874086] .reward-text[_ngcontent-ng-c3646874086] {
    font-size: 26px !important;
    margin-bottom: 32px !important;
    transform: rotate(-2deg) translate(-71px) translateY(10px)
  }
}

@media (max-width: 400px) {
  .about[_ngcontent-ng-c3646874086] .reward-text[_ngcontent-ng-c3646874086] {
    transform: rotate(-2deg) translate(-50px) translateY(10px)
  }
}

.about[_ngcontent-ng-c3646874086] .wtf-img[_ngcontent-ng-c3646874086] {
  position: absolute;
  bottom: -194px;
  left: 201px;
  width: 432px
}

@media screen and (max-width: 1450px) {
  .about[_ngcontent-ng-c3646874086] .wtf-img[_ngcontent-ng-c3646874086] {
    bottom: -178px;
    width: 380px
  }
}

@media screen and (max-width: 1370px) {
  .about[_ngcontent-ng-c3646874086] .wtf-img[_ngcontent-ng-c3646874086] {
    width: 340px
  }
}

@media screen and (max-width: 768px) {
  .about[_ngcontent-ng-c3646874086] .wtf-img[_ngcontent-ng-c3646874086] {
    width: 200px;
    left: -39px;
    bottom: -104px
  }
}

.about[_ngcontent-ng-c3646874086] .zap-img[_ngcontent-ng-c3646874086] {
  position: absolute;
  top: -190px;
  right: 0;
  width: 360px
}

@media screen and (max-width: 768px) {
  .about[_ngcontent-ng-c3646874086] .zap-img[_ngcontent-ng-c3646874086] {
    position: absolute;
    bottom: 213px;
    right: -46px;
    width: 53%;
    top: auto;
    transform: rotate(11deg)
  }
}

.about[_ngcontent-ng-c3646874086] .welcome-img[_ngcontent-ng-c3646874086] {
  position: absolute;
  top: -180px;
  left: 20px;
  width: 480px
}

@media screen and (max-width: 768px) {
  .about[_ngcontent-ng-c3646874086] .welcome-img[_ngcontent-ng-c3646874086] {
    width: 300px;
    top: -100px;
    left: 24px;
    z-index: 2
  }
}

@media (max-width: 400px) {
  .about[_ngcontent-ng-c3646874086] .welcome-img[_ngcontent-ng-c3646874086] {
    width: 280px;
    top: -95px
  }
}

.about[_ngcontent-ng-c3646874086] .background-image[_ngcontent-ng-c3646874086] {
  position: absolute;
  left: 0;
  width: 100%;
  top: -301px;
  z-index: -1
}

.votes[_ngcontent-ng-c1603420014] {
  position: relative;
  padding: 200px 0 150px
}

@media screen and (max-width: 768px) {
  .votes[_ngcontent-ng-c1603420014] {
    overflow: hidden;
    padding: 80px 0 30px;
    margin-top: -50px
  }
}

@media screen and (min-width: 992px) {
  .votes[_ngcontent-ng-c1603420014] .votesImg[_ngcontent-ng-c1603420014] {
    transform: scale(1.2);
    margin-top: 5%
  }
}

.votes[_ngcontent-ng-c1603420014] .box[_ngcontent-ng-c1603420014] {
  position: relative;
  border: 6px solid #000000;
  background: #000
}

@media screen and (max-width: 1450px) {
  .votes[_ngcontent-ng-c1603420014] .box[_ngcontent-ng-c1603420014] {
    min-height: 360px
  }
}

@media screen and (max-width: 1370px) {
  .votes[_ngcontent-ng-c1603420014] .box[_ngcontent-ng-c1603420014] {
    padding: 35px 25px;
    min-height: 245px
  }
}

@media screen and (max-width: 768px) {
  .votes[_ngcontent-ng-c1603420014] .box[_ngcontent-ng-c1603420014] {
    min-height: auto
  }
}

.votes[_ngcontent-ng-c1603420014] .started-btn[_ngcontent-ng-c1603420014] {
  width: max-content;
  position: absolute;
  top: 0;
  right: 110px;
  font-size: 25px;
  background: #c1daea;
  border: 4px solid #000000;
  -webkit-text-stroke: 8px black;
  font-weight: 800;
  padding: 5px 25px;
  color: #fff;
  z-index: 3;
  transform: rotate(-3deg);
  paint-order: stroke fill
}

@media screen and (max-width: 576px) {
  .votes[_ngcontent-ng-c1603420014] .started-btn[_ngcontent-ng-c1603420014] {
    font-size: 30px
  }
}

.votes[_ngcontent-ng-c1603420014] .box-shadow[_ngcontent-ng-c1603420014] {
  z-index: 2;
  position: relative;
  filter: drop-shadow(0px 0 15px rgba(254, 254, 254, .2098039216)) drop-shadow(1px 0 13px rgba(254, 254, 254, .2098039216)) drop-shadow(0 1px 5px rgba(254, 254, 254, .5098039216))
}

.votes[_ngcontent-ng-c1603420014] .votes[_ngcontent-ng-c1603420014] {
  position: absolute;
  width: 615px;
  top: -64px;
  left: -42px;
  z-index: -1
}

@media (max-width: 1930px) and (min-width: 1800px) {
  .votes[_ngcontent-ng-c1603420014] .votes[_ngcontent-ng-c1603420014] {
    left: 5px;
    width: 700px;
    top: -120px
  }
}

@media screen and (max-width: 1300px) {
  .votes[_ngcontent-ng-c1603420014] .votes[_ngcontent-ng-c1603420014] {
    width: 509px
  }
}

@media screen and (max-width: 1024px) {
  .votes[_ngcontent-ng-c1603420014] .votes[_ngcontent-ng-c1603420014] {
    position: relative;
    top: unset;
    left: unset;
    padding: 0;
    margin-top: -50px;
    z-index: 2
  }
}

.votes[_ngcontent-ng-c1603420014] .orange-box[_ngcontent-ng-c1603420014] {
  border: 4px solid #000000;
  background: #f5882d;
  padding: 4px 0;
  border-top: 0;
  width: calc(74% + 38px)
}

@media screen and (max-width: 576px) {
  .votes[_ngcontent-ng-c1603420014] .orange-box[_ngcontent-ng-c1603420014] {
    width: calc(75% + 19px);
    margin-left: 15px;
    z-index: 1;
    position: relative;
    border-width: 3px;
    border-top: 0
  }
}

.votes[_ngcontent-ng-c1603420014] .orange-box.two[_ngcontent-ng-c1603420014] {
  width: calc(73% + 148px);
  margin-left: -105px
}

@media screen and (max-width: 1399px) {
  .votes[_ngcontent-ng-c1603420014] .orange-box.two[_ngcontent-ng-c1603420014] {
    width: calc(71% + 152px)
  }
}

@media screen and (max-width: 1024px) {
  .votes[_ngcontent-ng-c1603420014] .orange-box.two[_ngcontent-ng-c1603420014] {
    width: calc(71% + 162px)
  }
}

@media screen and (max-width: 576px) {
  .votes[_ngcontent-ng-c1603420014] .orange-box.two[_ngcontent-ng-c1603420014] {
    width: calc(77% + 105px);
    margin-left: -40px
  }
}

.votes[_ngcontent-ng-c1603420014] .orange-box.three[_ngcontent-ng-c1603420014] {
  width: calc(100% + 160px);
  margin-left: -100px
}

@media screen and (max-width: 576px) {
  .votes[_ngcontent-ng-c1603420014] .orange-box.three[_ngcontent-ng-c1603420014] {
    width: calc(100% - 15px);
    margin-left: 15px
  }
}

.votes[_ngcontent-ng-c1603420014] .section-title[_ngcontent-ng-c1603420014] {
  left: 98px;
  top: -40px;
  paint-order: stroke fill;
  position: relative
}

@media screen and (max-width: 1370px) {
  .votes[_ngcontent-ng-c1603420014] .section-title[_ngcontent-ng-c1603420014] {
    right: 163px;
    top: -74px
  }
}

@media screen and (max-width: 768px) {
  .votes[_ngcontent-ng-c1603420014] .section-title[_ngcontent-ng-c1603420014] {
    position: static !important;
    max-width: 75%;
    margin-bottom: 130px;
    font-size: 33px
  }
}

@media screen and (max-width: 576px) {
  .votes[_ngcontent-ng-c1603420014] .section-title[_ngcontent-ng-c1603420014] {
    max-width: 230px;
    margin-bottom: 30px;
    font-size: 30px;
    margin-top: 20px;
    padding: 5px 20px
  }
}

.votes[_ngcontent-ng-c1603420014] .explore-button[_ngcontent-ng-c1603420014] {
  position: absolute;
  right: 150px;
  top: 55px;
  width: max-content;
  font-family: BadaBoom BB, sans-serif;
  font-size: 25px;
  background: #f5882d;
  border: 3px solid #000000;
  box-shadow: 0 0 32px #f5882d82;
  color: #fff;
  -webkit-text-stroke: 4px black;
  paint-order: stroke fill;
  font-weight: 500;
  transform: rotate(-3deg);
  padding: 10px 25px;
  z-index: 2
}

@media screen and (max-width: 576px) {
  .votes[_ngcontent-ng-c1603420014] .explore-button[_ngcontent-ng-c1603420014] {
    top: unset;
    bottom: 50px;
    right: unset;
    left: 42px;
    font-size: 20px
  }
}

.votes[_ngcontent-ng-c1603420014] .learn[_ngcontent-ng-c1603420014] {
  position: absolute;
  right: 120px;
  -webkit-text-stroke: 4px #000000;
  paint-order: stroke fill;
  transform: rotate(4deg);
  bottom: 55px
}

@media screen and (max-width: 1370px) {
  .votes[_ngcontent-ng-c1603420014] .learn[_ngcontent-ng-c1603420014] {
    right: 83px;
    bottom: 68px
  }
}

@media screen and (max-width: 768px) {
  .votes[_ngcontent-ng-c1603420014] .learn[_ngcontent-ng-c1603420014] {
    position: static !important;
    right: 0;
    text-align: center;
    margin-top: 50px
  }
}

.votes[_ngcontent-ng-c1603420014] .arrow[_ngcontent-ng-c1603420014] {
  margin-left: 246px;
  position: absolute;
  top: 10%
}

@media screen and (max-width: 576px) {
  .votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] {
    filter: drop-shadow(0px 0 7px rgba(254, 254, 254, .2098039216)) drop-shadow(1px 0 9px rgba(254, 254, 254, .2098039216)) drop-shadow(0 1px 5px rgba(254, 254, 254, .5098039216));
    padding: 15px 0 0 15px
  }
}

.votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card[_ngcontent-ng-c1603420014] {
  position: relative;
  min-height: 280px;
  width: calc(100% + 40px);
  border: 4px solid #000000;
  background-color: #000;
  clip-path: polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%)
}

.votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card[_ngcontent-ng-c1603420014] .box-card-inline[_ngcontent-ng-c1603420014] {
  height: 100%;
  min-height: 280px;
  width: 100%;
  padding: 40px 20% 20px 40px;
  clip-path: polygon(0% 0%, calc(100% - 3px) 0%, 75% 100%, 0% 100%);
  background-color: #c1daea;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px
}

.votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card[_ngcontent-ng-c1603420014] .box-card-inline[_ngcontent-ng-c1603420014] h3[_ngcontent-ng-c1603420014] {
  font-size: 40px;
  font-family: BadaBoom BB, sans-serif;
  text-align: start
}

.votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card[_ngcontent-ng-c1603420014] .box-card-inline[_ngcontent-ng-c1603420014] p[_ngcontent-ng-c1603420014] {
  font-size: 22px;
  word-spacing: 2px;
  letter-spacing: 1px;
  line-height: 30px;
  text-align: start;
  margin-bottom: 0
}

@media screen and (max-width: 1399px) {
  .votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card[_ngcontent-ng-c1603420014] .box-card-inline[_ngcontent-ng-c1603420014] p[_ngcontent-ng-c1603420014] {
    font-size: 18px
  }
}

@media screen and (max-width: 576px) {
  .votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card[_ngcontent-ng-c1603420014] .box-card-inline[_ngcontent-ng-c1603420014] {
    min-height: 400px;
    padding: 30px 35% 20px 30px
  }

  .votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card[_ngcontent-ng-c1603420014] .box-card-inline[_ngcontent-ng-c1603420014] h3[_ngcontent-ng-c1603420014] {
    font-size: 30px
  }

  .votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card[_ngcontent-ng-c1603420014] .box-card-inline[_ngcontent-ng-c1603420014] p[_ngcontent-ng-c1603420014] {
    font-size: 16px;
    line-height: 24px
  }
}

.votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card.second[_ngcontent-ng-c1603420014] {
  clip-path: polygon(22% 0%, 100% 0%, 77% 100%, 0% 100%);
  margin-left: -105px;
  width: calc(100% + 150px)
}

@media screen and (max-width: 576px) {
  .votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card.second[_ngcontent-ng-c1603420014] {
    margin-left: -55px
  }
}

.votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card.second[_ngcontent-ng-c1603420014] .box-card-inline[_ngcontent-ng-c1603420014] {
  clip-path: polygon(22% 0%, calc(100% - 3px) 0, 77% 100%, 2px 100%);
  padding: 20px 163px 20px 200px;
  justify-content: center
}

@media screen and (max-width: 576px) {
  .votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card.second[_ngcontent-ng-c1603420014] .box-card-inline[_ngcontent-ng-c1603420014] {
    padding: 0 30% 20px 122px
  }
}

.votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card.third[_ngcontent-ng-c1603420014] {
  clip-path: polygon(23% 0%, 100% 0%, 100% 100%, 0% 100%);
  margin-left: -100px;
  width: calc(100% + 160px)
}

@media screen and (max-width: 576px) {
  .votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card.third[_ngcontent-ng-c1603420014] {
    margin: 0;
    width: 100%;
    clip-path: polygon(30% 0%, 100% 0%, 100% 100%, 0% 100%)
  }

  .votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card.third[_ngcontent-ng-c1603420014] .box-card-inline[_ngcontent-ng-c1603420014] {
    clip-path: polygon(30% 0%, 100% 0%, 100% 100%, 0% 100%) !important;
    padding: 20px 20px 20px 100px !important
  }
}

.votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card.third[_ngcontent-ng-c1603420014] .box-card-inline[_ngcontent-ng-c1603420014] {
  clip-path: polygon(23% 0%, 100% 0%, 100% 100%, 0% 100%);
  justify-content: center;
  align-items: center;
  padding: 21px 75px 20px 180px
}

.votes[_ngcontent-ng-c1603420014] .outer-card[_ngcontent-ng-c1603420014] .box-card.third[_ngcontent-ng-c1603420014] .box-card-inline[_ngcontent-ng-c1603420014] p[_ngcontent-ng-c1603420014] {
  max-width: 400px
}

.votes .owl-theme .owl-nav .owl-next {
  position: absolute !important;
  top: 36% !important;
  right: 15% !important;
  height: 95px;
  background: none;
  width: 80px
}

.votes .owl-theme .owl-nav .owl-prev {
  position: absolute !important;
  top: 36% !important;
  left: -5% !important;
  height: 95px;
  background: none;
  width: 80px;
  z-index: 999 !important
}

@media screen and (max-width: 576px) {

  .votes .owl-theme .owl-nav .owl-next,
  .votes .owl-theme .owl-nav .owl-prev {
    height: 80px;
    width: 80px;
    top: 40% !important;
    right: -10px !important
  }

  .votes .owl-theme .owl-nav .owl-next img,
  .votes .owl-theme .owl-nav .owl-prev img {
    height: 100%;
    width: 100%
  }

  .votes .owl-theme .owl-nav .owl-prev {
    top: 40% !important;
    right: -2px !important
  }
}

.votes .owl-item {
  opacity: .5
}

.votes .owl-item.center {
  opacity: 1
}

.votes .owl-stage {
  padding-left: 50px !important;
  flex-wrap: nowrap;
  display: flex;
  width: max-content
}

@media screen and (max-width: 576px) {
  .votes .owl-stage {
    padding-left: 0 !important
  }
}

.votes .owl-theme .owl-nav .disabled {
  opacity: 0
}

.plans[_ngcontent-ng-c2416091851] {
  position: relative;
  padding: 80px 0
}

@media screen and (max-width: 768px) {
  .plans[_ngcontent-ng-c2416091851] {
    padding: 150px 0 80px;
    overflow: hidden
  }
}

@media screen and (max-width: 576px) {
  .plans[_ngcontent-ng-c2416091851] {
    margin-top: -100px
  }
}

.plans[_ngcontent-ng-c2416091851] .mobile-bg[_ngcontent-ng-c2416091851] {
  position: absolute;
  width: 100%;
  height: 300px;
  top: 0
}

.plans[_ngcontent-ng-c2416091851] .section-title[_ngcontent-ng-c2416091851] {
  position: absolute;
  left: 24%;
  top: -60px;
  z-index: 2;
  paint-order: stroke fill
}

@media (max-width: 1930px) and (min-width: 1800px) {
  .plans[_ngcontent-ng-c2416091851] .section-title[_ngcontent-ng-c2416091851] {
    left: 26%;
    top: -51px
  }
}

@media screen and (max-width: 1370px) {
  .plans[_ngcontent-ng-c2416091851] .section-title[_ngcontent-ng-c2416091851] {
    left: 26%
  }
}

@media screen and (max-width: 768px) {
  .plans[_ngcontent-ng-c2416091851] .section-title[_ngcontent-ng-c2416091851] {
    position: relative !important;
    font-size: 28px !important;
    left: -5%;
    width: 110% !important
  }
}

.plans[_ngcontent-ng-c2416091851] .box-main[_ngcontent-ng-c2416091851] {
  z-index: 2;
  position: relative;
  filter: drop-shadow(0px 0 15px rgba(254, 254, 254, .2098039216)) drop-shadow(1px 0 13px rgba(254, 254, 254, .2098039216)) drop-shadow(0 1px 5px rgba(254, 254, 254, .5098039216))
}

.plans[_ngcontent-ng-c2416091851] .box[_ngcontent-ng-c2416091851] {
  position: relative;
  border: 6px solid #000000;
  background: #000;
  box-shadow: 0 4px 8px #0003
}

@media screen and (max-width: 768px) {
  .plans[_ngcontent-ng-c2416091851] .box[_ngcontent-ng-c2416091851] {
    width: 110%;
    clip-path: polygon(0% 0%, 100% 0%, 68% 217%, 0% 100%) !important
  }

  .plans[_ngcontent-ng-c2416091851] .box[_ngcontent-ng-c2416091851] .inside[_ngcontent-ng-c2416091851] {
    padding: 20px !important;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 68% 217%, 0% 100%) !important;
    clip-path: polygon(0% 0%, 100% 0%, 68% 217%, 0% 100%) !important
  }
}

.plans[_ngcontent-ng-c2416091851] .box[_ngcontent-ng-c2416091851] .text-orange[_ngcontent-ng-c2416091851] {
  color: #e96a00;
  padding-right: 2px
}

.plans[_ngcontent-ng-c2416091851] h3[_ngcontent-ng-c2416091851] {
  font-family: BadaBoom BB, sans-serif
}

@media screen and (max-width: 768px) {
  .plans[_ngcontent-ng-c2416091851] h3[_ngcontent-ng-c2416091851] {
    color: #f0b90b !important;
    -webkit-text-stroke: 8px #000000;
    paint-order: stroke fill
  }
}

.plans[_ngcontent-ng-c2416091851] .orange-box[_ngcontent-ng-c2416091851] {
  border: 6px solid #000000;
  background: #f5882d;
  padding: 10px 0;
  border-top: 0;
  position: relative;
  z-index: 2
}

@media screen and (max-width: 768px) {
  .plans[_ngcontent-ng-c2416091851] .orange-box[_ngcontent-ng-c2416091851] {
    width: 94%
  }
}

.plans[_ngcontent-ng-c2416091851] .top-text[_ngcontent-ng-c2416091851] {
  background: #2045ca;
  border-radius: 50px;
  position: absolute;
  right: 109px;
  top: -25px;
  z-index: 3;
  width: 160px;
  padding: 15px 0
}

.plans[_ngcontent-ng-c2416091851] .top-text[_ngcontent-ng-c2416091851] p[_ngcontent-ng-c2416091851] {
  -webkit-text-stroke: 3px #000000;
  paint-order: stroke fill;
  letter-spacing: 1.1px
}

@media screen and (max-width: 768px) {
  .plans[_ngcontent-ng-c2416091851] .top-text[_ngcontent-ng-c2416091851] {
    right: 40px !important
  }
}

.plans[_ngcontent-ng-c2416091851] .top-text3[_ngcontent-ng-c2416091851] {
  width: 240px !important;
  right: 265px !important
}

@media screen and (max-width: 768px) {
  .plans[_ngcontent-ng-c2416091851] .top-text3[_ngcontent-ng-c2416091851] {
    right: 40px !important
  }
}

.plans[_ngcontent-ng-c2416091851] .circle[_ngcontent-ng-c2416091851] {
  background: #f5882d;
  border-radius: 25px;
  padding: 10px;
  width: 7px;
  box-shadow: 0 0 19.51px #f5882d;
  border: 1px solid #000000
}

.plans[_ngcontent-ng-c2416091851] .line[_ngcontent-ng-c2416091851] {
  position: absolute;
  z-index: 1;
  top: -80px;
  right: -171px
}

.plans[_ngcontent-ng-c2416091851] .egg1[_ngcontent-ng-c2416091851] {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 86px;
  right: 78px
}

.plans[_ngcontent-ng-c2416091851] .high-five[_ngcontent-ng-c2416091851] {
  position: relative;
  top: -100px;
  height: 400px;
  width: 450px;
  z-index: 2
}

.plans[_ngcontent-ng-c2416091851] .music[_ngcontent-ng-c2416091851] {
  position: relative;
  top: -68px;
  height: 440px;
  width: 515px;
  z-index: 2
}

.plans[_ngcontent-ng-c2416091851] .egg2[_ngcontent-ng-c2416091851] {
  width: 200px;
  position: relative;
  height: 200px;
  top: 37px
}

.plans[_ngcontent-ng-c2416091851] .egg3[_ngcontent-ng-c2416091851] {
  top: 110px !important
}

@media screen and (max-width: 1370px) {
  .plans[_ngcontent-ng-c2416091851] .egg3[_ngcontent-ng-c2416091851] {
    right: 51px !important
  }
}

.plans[_ngcontent-ng-c2416091851] .spin[_ngcontent-ng-c2416091851] {
  position: relative;
  height: 594px;
  max-width: 670px;
  top: -72px;
  z-index: 3
}

@media screen and (max-width: 1370px) {
  .plans[_ngcontent-ng-c2416091851] .spin[_ngcontent-ng-c2416091851] {
    width: 100%;
    right: 0;
    max-width: 800px
  }
}

@media screen and (max-width: 1300px) {
  .plans[_ngcontent-ng-c2416091851] .spin[_ngcontent-ng-c2416091851] {
    width: 455px
  }
}

@media screen and (max-width: 768px) {
  .plans[_ngcontent-ng-c2416091851] .spin[_ngcontent-ng-c2416091851] {
    height: 420px;
    top: -41px;
    max-width: 100%
  }
}

.plans[_ngcontent-ng-c2416091851] .drink[_ngcontent-ng-c2416091851] {
  position: absolute;
  bottom: 87px;
  width: 150px;
  left: 46%;
  z-index: 4
}

.plans[_ngcontent-ng-c2416091851] .column[_ngcontent-ng-c2416091851] {
  position: relative
}

@media screen and (max-width: 768px) {
  .plans[_ngcontent-ng-c2416091851] ul[_ngcontent-ng-c2416091851] {
    width: 90% !important;
    padding: 8px 15px;
    margin-bottom: 0
  }

  .plans[_ngcontent-ng-c2416091851] ul[_ngcontent-ng-c2416091851] li[_ngcontent-ng-c2416091851] {
    font-size: 18px
  }
}

.plans[_ngcontent-ng-c2416091851] .box1[_ngcontent-ng-c2416091851] {
  position: relative;
  z-index: 2;
  clip-path: polygon(0% 0%, 100% 0%, 68% 190%, 0% 100%)
}

.plans[_ngcontent-ng-c2416091851] .box1[_ngcontent-ng-c2416091851] ul[_ngcontent-ng-c2416091851] {
  width: 500px
}

.plans[_ngcontent-ng-c2416091851] .box1[_ngcontent-ng-c2416091851] .inside[_ngcontent-ng-c2416091851] {
  padding: 40px 40px 60px;
  background: #e0e0e0;
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 68% 190%, 0% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 68% 190%, 0% 100%)
}

.plans[_ngcontent-ng-c2416091851] .orange1[_ngcontent-ng-c2416091851] {
  width: 83.2%;
  position: relative;
  z-index: 2
}

@media screen and (max-width: 768px) {
  .plans[_ngcontent-ng-c2416091851] .orange1[_ngcontent-ng-c2416091851] {
    width: 94%
  }
}

.plans[_ngcontent-ng-c2416091851] .box2[_ngcontent-ng-c2416091851] {
  position: relative;
  z-index: 2;
  clip-path: polygon(16% 0%, 137% 0%, 68% 190%, 0% 100%)
}

.plans[_ngcontent-ng-c2416091851] .box2[_ngcontent-ng-c2416091851] ul[_ngcontent-ng-c2416091851] {
  width: 430px
}

.plans[_ngcontent-ng-c2416091851] .box2[_ngcontent-ng-c2416091851] .inside[_ngcontent-ng-c2416091851] {
  padding: 40px 40px 60px;
  background: #c1daea;
  -webkit-clip-path: polygon(16% 0%, 137% 0%, 68% 190%, 0% 100%);
  clip-path: polygon(16% 0%, 137% 0%, 68% 190%, 0% 100%)
}

.plans[_ngcontent-ng-c2416091851] .box3[_ngcontent-ng-c2416091851] {
  clip-path: polygon(0% 0%, 82% 0%, 100% 100%, 0% 100%)
}

.plans[_ngcontent-ng-c2416091851] .box3[_ngcontent-ng-c2416091851] ul[_ngcontent-ng-c2416091851] {
  width: 500px
}

.plans[_ngcontent-ng-c2416091851] .box3[_ngcontent-ng-c2416091851] .inside[_ngcontent-ng-c2416091851] {
  padding: 40px 40px 20px;
  background: #e0e0e0;
  -webkit-clip-path: polygon(0% 0%, 82% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 0%, 82% 0%, 100% 100%, 0% 100%)
}

.plans[_ngcontent-ng-c2416091851] .learn[_ngcontent-ng-c2416091851] {
  position: absolute;
  right: 37%;
  z-index: 4;
  -webkit-text-stroke: 5px #000000;
  paint-order: stroke fill;
  transform: rotate(4deg);
  bottom: 9%;
  border-bottom: 4px solid #F5882D
}

@media screen and (max-width: 1370px) {
  .plans[_ngcontent-ng-c2416091851] .learn[_ngcontent-ng-c2416091851] {
    right: 32%
  }
}

@media screen and (max-width: 1024px) {
  .plans[_ngcontent-ng-c2416091851] .learn[_ngcontent-ng-c2416091851] {
    right: 50%;
    bottom: 70px;
    transform: rotate(4deg) translate(50%);
    width: max-content;
    border-bottom: none
  }
}

.flockenomics[_ngcontent-ng-c2760964342] {
  text-align: center;
  position: relative;
  overflow: hidden;
  padding-bottom: 150px;
  margin-top: -230px;
  padding-top: 200px
}

@media screen and (max-width: 768px) {
  .flockenomics[_ngcontent-ng-c2760964342] {
    margin-top: -180px;
    padding-top: 70px
  }
}

.flockenomics[_ngcontent-ng-c2760964342] .info-wrapper[_ngcontent-ng-c2760964342] {
  order: 2
}

@media screen and (min-width: 992px) {
  .flockenomics[_ngcontent-ng-c2760964342] .info-wrapper[_ngcontent-ng-c2760964342] {
    order: 1
  }
}

@media (max-width: 992px) {
  .flockenomics[_ngcontent-ng-c2760964342] .info-wrapper[_ngcontent-ng-c2760964342] .section-title[_ngcontent-ng-c2760964342] {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translate(-50%);
    margin: 0 !important
  }
}

@media screen and (max-width: 576px) {
  .flockenomics[_ngcontent-ng-c2760964342] .info-wrapper[_ngcontent-ng-c2760964342] .section-title[_ngcontent-ng-c2760964342] {
    font-size: 30px;
    border-width: 3px;
    -webkit-text-stroke: 4px black;
    left: 10px;
    transform: rotate(-4deg)
  }
}

.flockenomics[_ngcontent-ng-c2760964342] .info-wrapper[_ngcontent-ng-c2760964342] .info-sec[_ngcontent-ng-c2760964342] {
  display: flex;
  flex-direction: column;
  align-items: start
}

.flockenomics[_ngcontent-ng-c2760964342] .info-wrapper[_ngcontent-ng-c2760964342] .info-sec[_ngcontent-ng-c2760964342] p[_ngcontent-ng-c2760964342] {
  text-align: left
}

.flockenomics[_ngcontent-ng-c2760964342] .info-wrapper[_ngcontent-ng-c2760964342] .info-sec[_ngcontent-ng-c2760964342] p.sec-heading[_ngcontent-ng-c2760964342] {
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  font-family: BadaBoom BB, sans-serif;
  color: #f0b90b;
  -webkit-text-stroke: 3px black;
  paint-order: stroke fill
}

.flockenomics[_ngcontent-ng-c2760964342] .info-wrapper[_ngcontent-ng-c2760964342] .info-sec[_ngcontent-ng-c2760964342] p.sec-desc[_ngcontent-ng-c2760964342] {
  font-family: Hey Comic, sans-serif;
  color: #c1daea;
  line-height: 25px;
  letter-spacing: 1px
}

.flockenomics[_ngcontent-ng-c2760964342] .info-wrapper[_ngcontent-ng-c2760964342] .flockenomic-brid-image[_ngcontent-ng-c2760964342] {
  position: absolute;
  width: 100%;
  max-width: 430px;
  height: auto;
  left: 21%;
  bottom: -158px
}

@media (max-width: 992px) {
  .flockenomics[_ngcontent-ng-c2760964342] .info-wrapper[_ngcontent-ng-c2760964342] .flockenomic-brid-image[_ngcontent-ng-c2760964342] {
    position: relative;
    left: unset;
    bottom: unset
  }
}

.flockenomics[_ngcontent-ng-c2760964342] .right-content[_ngcontent-ng-c2760964342] {
  order: 1
}

@media (max-width: 992px) {
  .flockenomics[_ngcontent-ng-c2760964342] .right-content[_ngcontent-ng-c2760964342] {
    padding-top: 300px;
    background-image: url(../src/asset/images/svg-icons/flockenomics-mobile-bg.svg);
    background-size: cover;
    background-position: center
  }
}

@media screen and (max-width: 576px) {
  .flockenomics[_ngcontent-ng-c2760964342] .right-content[_ngcontent-ng-c2760964342] {
    padding-top: 200px
  }
}

@media screen and (min-width: 992px) {
  .flockenomics[_ngcontent-ng-c2760964342] .right-content[_ngcontent-ng-c2760964342] {
    order: 2
  }
}

.flockenomics[_ngcontent-ng-c2760964342] .right-content[_ngcontent-ng-c2760964342] .flockenomic-graph-image[_ngcontent-ng-c2760964342] {
  width: 90%;
  height: auto
}

@media screen and (min-width: 1400px) {
  .flockenomics[_ngcontent-ng-c2760964342] .right-content[_ngcontent-ng-c2760964342] .flockenomic-graph-image[_ngcontent-ng-c2760964342] {
    transform: scale(1.5) translate(20%)
  }
}

@media screen and (min-width: 992px) {
  .flockenomics[_ngcontent-ng-c2760964342] .right-content[_ngcontent-ng-c2760964342] .flockenomic-graph-image[_ngcontent-ng-c2760964342] {
    width: 100%;
    transform: scale(1.3) translate(10%)
  }
}

@media screen and (max-width: 576px) {
  .flockenomics[_ngcontent-ng-c2760964342] .right-content[_ngcontent-ng-c2760964342] .flockenomic-graph-image[_ngcontent-ng-c2760964342] {
    width: 100%;
    margin: 0 auto
  }
}

.flockenomics[_ngcontent-ng-c2760964342] .right-content[_ngcontent-ng-c2760964342] .flockenomic-headphone-image[_ngcontent-ng-c2760964342] {
  display: none;
  position: absolute;
  width: 160px;
  height: 160px;
  bottom: -120px;
  right: 100px;
  transform: rotate(30deg)
}

@media screen and (min-width: 992px) {
  .flockenomics[_ngcontent-ng-c2760964342] .right-content[_ngcontent-ng-c2760964342] .flockenomic-headphone-image[_ngcontent-ng-c2760964342] {
    display: block
  }
}

.flockenomics .owl-carousel .owl-nav {
  width: fit-content;
  margin: 0 auto
}

.flockenomics .owl-carousel .owl-nav .owl-prev,
.flockenomics .owl-carousel .owl-nav .owl-next {
  padding: 0;
  background-color: transparent
}

.flockenomics .owl-carousel .owl-nav .owl-prev:active,
.flockenomics .owl-carousel .owl-nav .owl-next:active {
  opacity: .7
}

.flockenomics .owl-carousel .owl-nav .owl-prev img,
.flockenomics .owl-carousel .owl-nav .owl-next img {
  padding: 15px 30px;
  width: 90px;
  border-width: 3px
}

@media screen and (max-width: 576px) {

  .flockenomics .owl-carousel .owl-nav .owl-prev img,
  .flockenomics .owl-carousel .owl-nav .owl-next img {
    padding: 10px 20px;
    width: 60px;
    border-width: 2px
  }
}

.flockenomics .owl-carousel .owl-nav .owl-prev img.left-arrow,
.flockenomics .owl-carousel .owl-nav .owl-next img.left-arrow {
  transform: rotate(180deg)
}

.become-flockerz[_ngcontent-ng-c2990795050] {
  text-align: center;
  position: relative;
  margin-top: -120px;
  padding-top: 120px;
  padding-bottom: 50px
}

@media screen and (max-width: 768px) {
  .become-flockerz[_ngcontent-ng-c2990795050] {
    margin-top: -230px
  }
}

@media screen and (max-width: 576px) {
  .become-flockerz[_ngcontent-ng-c2990795050] {
    overflow: hidden
  }
}

.become-flockerz[_ngcontent-ng-c2990795050] .section-title[_ngcontent-ng-c2990795050] {
  margin-right: 12%
}

.become-flockerz[_ngcontent-ng-c2990795050] .become-card[_ngcontent-ng-c2990795050] {
  min-height: 280px;
  width: calc(100% + 30px);
  border: 4px solid #000000;
  background-color: #000;
  clip-path: polygon(0 0, 100% 0%, 96% 88%, 7% 100%)
}

@media screen and (max-width: 1024px) {
  .become-flockerz[_ngcontent-ng-c2990795050] .become-card[_ngcontent-ng-c2990795050] {
    width: 100%
  }
}

@media screen and (max-width: 576px) {
  .become-flockerz[_ngcontent-ng-c2990795050] .become-card[_ngcontent-ng-c2990795050] {
    clip-path: polygon(0 0, 100% 0%, 96% 88%, 7% 94%)
  }
}

.become-flockerz[_ngcontent-ng-c2990795050] .become-card[_ngcontent-ng-c2990795050] .become-card-inline[_ngcontent-ng-c2990795050] {
  height: 100%;
  min-height: 280px;
  width: 100%;
  padding: 40px 80px 20px;
  clip-path: polygon(0 0, 100% 0%, 96% 88%, 7% 100%);
  background-color: #e0e0e0
}

.become-flockerz[_ngcontent-ng-c2990795050] .become-card[_ngcontent-ng-c2990795050] .become-card-inline[_ngcontent-ng-c2990795050] h3[_ngcontent-ng-c2990795050] {
  font-size: 40px;
  font-family: BadaBoom BB, sans-serif;
  text-align: start
}

.become-flockerz[_ngcontent-ng-c2990795050] .become-card[_ngcontent-ng-c2990795050] .become-card-inline[_ngcontent-ng-c2990795050] p[_ngcontent-ng-c2990795050] {
  font-size: 18px;
  word-spacing: 2px;
  letter-spacing: 1x;
  line-height: 30px;
  text-align: start
}

.become-flockerz[_ngcontent-ng-c2990795050] .become-card[_ngcontent-ng-c2990795050] .become-card-inline[_ngcontent-ng-c2990795050] a[_ngcontent-ng-c2990795050] {
  color: #f5882d;
  text-decoration: underline !important
}

@media screen and (max-width: 576px) {
  .become-flockerz[_ngcontent-ng-c2990795050] .become-card[_ngcontent-ng-c2990795050] .become-card-inline[_ngcontent-ng-c2990795050] {
    padding: 20px 20px 85px 40px;
    clip-path: polygon(0 0, 100% 0%, 96% 88%, 7% 94%)
  }

  .become-flockerz[_ngcontent-ng-c2990795050] .become-card[_ngcontent-ng-c2990795050] .become-card-inline[_ngcontent-ng-c2990795050] h3[_ngcontent-ng-c2990795050] {
    font-size: 30px
  }

  .become-flockerz[_ngcontent-ng-c2990795050] .become-card[_ngcontent-ng-c2990795050] .become-card-inline[_ngcontent-ng-c2990795050] p[_ngcontent-ng-c2990795050] {
    font-size: 16px;
    line-height: 24px
  }
}

.become-flockerz[_ngcontent-ng-c2990795050] .become-card.second[_ngcontent-ng-c2990795050] {
  clip-path: polygon(6% 0, 95% 0%, 95% 71%, 2% 88%)
}

.become-flockerz[_ngcontent-ng-c2990795050] .become-card.second[_ngcontent-ng-c2990795050] .become-card-inline[_ngcontent-ng-c2990795050] {
  clip-path: polygon(6% 0, 95% 0%, 95% 71%, 2% 88%);
  background-color: #c1daea
}

@media screen and (max-width: 576px) {

  .become-flockerz[_ngcontent-ng-c2990795050] .become-card.second[_ngcontent-ng-c2990795050],
  .become-flockerz[_ngcontent-ng-c2990795050] .become-card.second[_ngcontent-ng-c2990795050] .become-card-inline[_ngcontent-ng-c2990795050] {
    clip-path: polygon(6% 0, 95% 0%, 95% 81%, 2% 93%)
  }
}

.become-flockerz[_ngcontent-ng-c2990795050] .become-card.third[_ngcontent-ng-c2990795050] {
  clip-path: polygon(0% 0, 93% 0%, 100% 100%, 0% 69%)
}

.become-flockerz[_ngcontent-ng-c2990795050] .become-card.third[_ngcontent-ng-c2990795050] .become-card-inline[_ngcontent-ng-c2990795050] {
  clip-path: polygon(0% 0, 93% 0%, 100% 100%, 0% 69%);
  background-color: #c1daea
}

@media screen and (max-width: 576px) {

  .become-flockerz[_ngcontent-ng-c2990795050] .become-card.third[_ngcontent-ng-c2990795050],
  .become-flockerz[_ngcontent-ng-c2990795050] .become-card.third[_ngcontent-ng-c2990795050] .become-card-inline[_ngcontent-ng-c2990795050] {
    clip-path: polygon(0% 0, 93% 0%, 100% 92%, 0% 74%)
  }
}

.become-flockerz[_ngcontent-ng-c2990795050] .background-image[_ngcontent-ng-c2990795050] {
  position: absolute;
  left: -10%;
  width: 50%;
  bottom: -10vw
}

@media screen and (max-width: 576px) {
  .become-flockerz[_ngcontent-ng-c2990795050] .background-image[_ngcontent-ng-c2990795050] {
    left: 0;
    width: 150%;
    bottom: -9vw
  }
}

.become-flockerz[_ngcontent-ng-c2990795050] .buy-and-stake-button[_ngcontent-ng-c2990795050] {
  position: absolute;
  left: calc(54% + 40px);
  bottom: 8px;
  width: max-content;
  font-family: BadaBoom BB, sans-serif;
  font-size: 30px;
  background: #f5882d;
  border: 3px solid #000000;
  box-shadow: 0 0 32px #f5882d82;
  color: #fff;
  -webkit-text-stroke: 6px black;
  paint-order: stroke fill;
  font-weight: 500;
  transform: rotate(-2deg);
  padding: 10px 25px;
  z-index: 1
}

@media (max-width: 1500px) {
  .become-flockerz[_ngcontent-ng-c2990795050] .buy-and-stake-button[_ngcontent-ng-c2990795050] {
    left: calc(54% + 40px)
  }
}

@media screen and (max-width: 1199px) {
  .become-flockerz[_ngcontent-ng-c2990795050] .buy-and-stake-button[_ngcontent-ng-c2990795050] {
    right: 0
  }
}

@media screen and (max-width: 1024px) {
  .become-flockerz[_ngcontent-ng-c2990795050] .buy-and-stake-button[_ngcontent-ng-c2990795050] {
    left: unset;
    right: 50%;
    bottom: -70px;
    transform: translate(50%) rotate(-3deg)
  }
}

@media screen and (max-width: 576px) {
  .become-flockerz[_ngcontent-ng-c2990795050] .buy-and-stake-button[_ngcontent-ng-c2990795050] {
    font-size: 20px;
    border-width: 2px;
    -webkit-text-stroke: 4px black
  }
}

.become-flockerz[_ngcontent-ng-c2990795050] .arrow[_ngcontent-ng-c2990795050] {
  width: 50px
}

.become-flockerz .owl-carousel .owl-nav {
  width: fit-content;
  margin: 50px auto;
  transform: translate(-20px)
}

.become-flockerz .owl-carousel .owl-nav .owl-prev,
.become-flockerz .owl-carousel .owl-nav .owl-next {
  padding: 0;
  background-color: transparent
}

.become-flockerz .owl-carousel .owl-nav .owl-prev:active,
.become-flockerz .owl-carousel .owl-nav .owl-next:active {
  opacity: .7
}

.become-flockerz .owl-carousel .owl-nav .owl-prev img,
.become-flockerz .owl-carousel .owl-nav .owl-next img {
  padding: 15px 30px;
  width: 90px;
  border-width: 3px
}

@media screen and (max-width: 576px) {

  .become-flockerz .owl-carousel .owl-nav .owl-prev img,
  .become-flockerz .owl-carousel .owl-nav .owl-next img {
    padding: 10px 20px;
    width: 60px;
    border-width: 2px
  }
}

.become-flockerz .owl-carousel .owl-nav .owl-prev img.left-arrow,
.become-flockerz .owl-carousel .owl-nav .owl-next img.left-arrow {
  transform: rotate(180deg)
}

@media screen and (max-width: 1024px) {
  .become-flockerz .owl-carousel .owl-nav {
    margin: 20px auto 50px;
    transform: none
  }
}

.videos[_ngcontent-ng-c500873678] {
  text-align: center;
  position: relative;
  padding-bottom: 150px
}

.videos[_ngcontent-ng-c500873678] .section-title[_ngcontent-ng-c500873678] {
  padding-inline: 100px
}

.videos[_ngcontent-ng-c500873678] .omg-img[_ngcontent-ng-c500873678] {
  position: absolute;
  right: 0;
  top: -100px
}

.videos[_ngcontent-ng-c500873678] .video-card[_ngcontent-ng-c500873678] {
  width: 100%;
  height: 200px
}

.videos[_ngcontent-ng-c500873678] .video-card[_ngcontent-ng-c500873678] img[_ngcontent-ng-c500873678] {
  height: 100%;
  width: 100%;
  object-fit: contain
}

@media screen and (max-width: 1024px) {
  .videos[_ngcontent-ng-c500873678] .section-title[_ngcontent-ng-c500873678] {
    padding-inline: 50px;
    font-size: 35px;
    -webkit-text-stroke: 2px black
  }

  .videos[_ngcontent-ng-c500873678] .omg-img[_ngcontent-ng-c500873678] {
    width: 200px;
    height: 120px;
    top: -50px
  }

  .videos[_ngcontent-ng-c500873678] .omg-img[_ngcontent-ng-c500873678] svg[_ngcontent-ng-c500873678] {
    width: 200px !important;
    height: 120px !important
  }
}

@media screen and (max-width: 576px) {
  .videos[_ngcontent-ng-c500873678] {
    padding-top: 150px;
    padding-bottom: 50px
  }

  .videos[_ngcontent-ng-c500873678] .section-title[_ngcontent-ng-c500873678] {
    padding-inline: 40px;
    font-size: 28px;
    -webkit-text-stroke: 4px black;
    transform: rotate(-3deg) translate(-20px)
  }

  .videos[_ngcontent-ng-c500873678] .omg-img[_ngcontent-ng-c500873678] {
    top: -150px;
    right: unset;
    left: -50px
  }

  .videos[_ngcontent-ng-c500873678] .video-card[_ngcontent-ng-c500873678] {
    height: 150px
  }
}

.faq[_ngcontent-ng-c4109746866] {
  background: transparent url(../src/asset/images/svg-icons/faq_bg_desktop.svg) no-repeat 96% 80px;
  background-size: cover;
  padding: 100px 0 70px;
  margin-top: -100px;
  position: relative
}

@media screen and (max-width: 576px) {
  .faq[_ngcontent-ng-c4109746866] {
    background: none;
    padding: 120px 0 20px;
    width: 100%;
    margin: -120px auto 0;
    text-align: left;
    overflow: hidden
  }

  .faq[_ngcontent-ng-c4109746866] .section-title[_ngcontent-ng-c4109746866] {
    margin-bottom: 30px
  }

  .faq[_ngcontent-ng-c4109746866] .left-content[_ngcontent-ng-c4109746866] {
    background: transparent url(../src/asset/images/svg-icons/faq_bg_desktop.svg);
    background-size: 233%;
    background-repeat: no-repeat;
    background-position: center
  }
}

@media screen and (max-width: 576px) {
  .faq[_ngcontent-ng-c4109746866] .poof-image[_ngcontent-ng-c4109746866] {
    height: 150px;
    width: 180px;
    position: absolute;
    top: -93px;
    right: 10px;
    z-index: 1
  }
}

.faq[_ngcontent-ng-c4109746866] .stroke-btn[_ngcontent-ng-c4109746866] {
  font-family: BadaBoom BB, sans-serif;
  width: max-content;
  font-size: 60px;
  color: #ff8000;
  padding: 0 15px
}

.faq[_ngcontent-ng-c4109746866] .stroke-btn.title[_ngcontent-ng-c4109746866] {
  border: 6px solid #000000;
  background: #c1daea;
  color: #f0b90b;
  rotate: -6deg;
  margin-bottom: 60px
}

.faq[_ngcontent-ng-c4109746866] .stroke-btn.stake[_ngcontent-ng-c4109746866] {
  font-size: 30px;
  border: 3px solid #000000;
  box-shadow: 0 0 32px #f5882d82;
  -webkit-text-stroke: 6px black;
  paint-order: stroke fill;
  background: #f5882d;
  color: #fff;
  font-weight: 500;
  rotate: -6deg;
  padding: 10px 25px;
  margin-top: 30px
}

@media screen and (max-width: 576px) {
  .faq[_ngcontent-ng-c4109746866] .stroke-btn.stake[_ngcontent-ng-c4109746866] {
    font-size: 25px;
    border-width: 2px;
    -webkit-text-stroke: 4px black;
    paint-order: stroke fill;
    margin-bottom: 70px
  }
}

.faq[_ngcontent-ng-c4109746866] .flock[_ngcontent-ng-c4109746866] {
  font-size: 90px;
  height: 100px;
  rotate: 12deg;
  -webkit-text-stroke: 3px black;
  margin-left: 40px
}

@media screen and (max-width: 576px) {
  .faq[_ngcontent-ng-c4109746866] .flock[_ngcontent-ng-c4109746866] {
    rotate: -1deg;
    font-size: 63px;
    margin-left: 0
  }
}

.faq[_ngcontent-ng-c4109746866] .telegram-icon[_ngcontent-ng-c4109746866] {
  margin-left: 20px
}

.faq[_ngcontent-ng-c4109746866] .telegram-icon[_ngcontent-ng-c4109746866] svg[_ngcontent-ng-c4109746866] path[_ngcontent-ng-c4109746866] {
  fill: #f5882d
}

.faq[_ngcontent-ng-c4109746866] .twitter-icon[_ngcontent-ng-c4109746866] {
  margin-left: 40px
}

.faq[_ngcontent-ng-c4109746866] .twitter-icon[_ngcontent-ng-c4109746866] svg[_ngcontent-ng-c4109746866] rect[_ngcontent-ng-c4109746866] {
  fill: #f5882d
}

@media screen and (max-width: 576px) {
  .faq[_ngcontent-ng-c4109746866] .twitter-icon[_ngcontent-ng-c4109746866] {
    margin-left: 0
  }
}

.faq[_ngcontent-ng-c4109746866] .head[_ngcontent-ng-c4109746866] {
  height: 45px
}

.faq[_ngcontent-ng-c4109746866] .head[_ngcontent-ng-c4109746866] .text[_ngcontent-ng-c4109746866] {
  paint-order: stroke fill
}

.faq[_ngcontent-ng-c4109746866] .join_us[_ngcontent-ng-c4109746866] {
  width: 100%;
  max-width: 630px
}

@media screen and (max-width: 576px) {
  .faq[_ngcontent-ng-c4109746866] .join_us[_ngcontent-ng-c4109746866] {
    width: 115%
  }
}

.faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] {
  min-height: 320px
}

.faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card[_ngcontent-ng-c4109746866] {
  width: 100%;
  margin-bottom: 20px
}

.faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.one[_ngcontent-ng-c4109746866] {
  background: transparent url(../src/asset/images/svg-icons/faq-one.png) no-repeat 0 0;
  background-size: 100% 100%;
  padding: 44px 70px 35px 100px
}

@media (min-width: 1440px) and (max-height: 800px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.one[_ngcontent-ng-c4109746866] {
    height: 306px
  }
}

@media (min-width: 1500px) and (max-width: 2300px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.one[_ngcontent-ng-c4109746866] {
    height: 322px;
    padding: 67px 70px 35px 100px
  }
}

@media screen and (max-width: 576px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.one[_ngcontent-ng-c4109746866] {
    padding: 71px 50px 35px;
    min-width: 514px
  }
}

@media (max-width: 430px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.one[_ngcontent-ng-c4109746866] {
    padding: 71px 140px 35px 70px;
    transform: translate(50px)
  }
}

.faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.two[_ngcontent-ng-c4109746866] {
  background: transparent url(../src/asset/images/svg-icons/faq-two.png) no-repeat 0 0;
  background-size: 100% 100%;
  padding: 25px 53px 64px 100px
}

@media (min-width: 1440px) and (max-height: 800px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.two[_ngcontent-ng-c4109746866] {
    height: 261px
  }
}

@media (min-width: 1500px) and (max-width: 2300px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.two[_ngcontent-ng-c4109746866] {
    height: 275px;
    padding: 40px 53px 64px 100px
  }
}

@media screen and (max-width: 576px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.two[_ngcontent-ng-c4109746866] {
    padding: 38px 50px 80px;
    min-width: 514px
  }
}

@media (max-width: 430px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.two[_ngcontent-ng-c4109746866] {
    padding: 33px 140px 69px 70px;
    transform: translate(50px)
  }
}

.faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.three[_ngcontent-ng-c4109746866] {
  background: transparent url(../src/asset/images/svg-icons/faq-three.png) no-repeat 0 0;
  background-size: 100% 100%;
  padding: 57px 70px 41px 100px;
  margin-top: -54px
}

@media (min-width: 1440px) and (max-height: 800px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.three[_ngcontent-ng-c4109746866] {
    height: 250px
  }
}

@media (min-width: 1500px) and (max-width: 2300px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.three[_ngcontent-ng-c4109746866] {
    height: 265px;
    padding: 74px 91px 41px 100px
  }
}

@media screen and (max-width: 576px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.three[_ngcontent-ng-c4109746866] {
    padding: 100px 50px 40px;
    min-width: 514px
  }
}

@media (max-width: 430px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.three[_ngcontent-ng-c4109746866] {
    padding: 100px 136px 40px 61px;
    transform: translate(50px)
  }
}

.faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.four[_ngcontent-ng-c4109746866] {
  background: transparent url(../src/asset/images/svg-icons/faq-four.png) no-repeat 0 0;
  background-size: 100% 100%;
  padding: 34px 53px 69px 125px;
  margin-left: -30px
}

@media (min-width: 1440px) and (max-height: 800px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.four[_ngcontent-ng-c4109746866] {
    height: 296px
  }
}

@media (min-width: 1500px) and (max-width: 2300px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.four[_ngcontent-ng-c4109746866] {
    height: 265px;
    padding: 44px 70px 41px 130px
  }
}

@media screen and (max-width: 576px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.four[_ngcontent-ng-c4109746866] {
    padding: 40px 38px 70px 78px;
    min-width: 514px
  }
}

@media (max-width: 430px) {
  .faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card.four[_ngcontent-ng-c4109746866] {
    padding: 40px 137px 70px 70px;
    transform: translate(78px)
  }
}

.faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card[_ngcontent-ng-c4109746866] h3[_ngcontent-ng-c4109746866] {
  font-family: BadaBoom BB, sans-serif;
  font-size: 32px
}

.faq[_ngcontent-ng-c4109746866] .faq-cont[_ngcontent-ng-c4109746866] .faq_card[_ngcontent-ng-c4109746866] p[_ngcontent-ng-c4109746866] {
  font-family: Hey Comic, sans-serif;
  font-size: 16px;
  margin: 0;
  line-height: 28px
}

.feature-wrapper[_ngcontent-ng-c397336586] {
  position: relative;
  padding: 15px 0;
  background: #4d6cc3
}

.feature-wrapper[_ngcontent-ng-c397336586] .container[_ngcontent-ng-c397336586] {
  opacity: .5
}

@media screen and (max-width: 576px) {
  .feature-wrapper[_ngcontent-ng-c397336586] .container[_ngcontent-ng-c397336586] div[_ngcontent-ng-c397336586] {
    width: calc(50% - 25px)
  }
}

.featured-img[_ngcontent-ng-c397336586] {
  max-height: 80px;
  width: auto !important;
  filter: contrast(200%)
}

.swapSection[_ngcontent-ng-c1685097014] .body-section[_ngcontent-ng-c1685097014] label[_ngcontent-ng-c1685097014] {
  letter-spacing: 1px;
  color: #4d6cc3
}

.swapSection[_ngcontent-ng-c1685097014] .body-section[_ngcontent-ng-c1685097014] .amountField[_ngcontent-ng-c1685097014] {
  position: relative
}

.swapSection[_ngcontent-ng-c1685097014] .body-section[_ngcontent-ng-c1685097014] .amountField[_ngcontent-ng-c1685097014] input[_ngcontent-ng-c1685097014] {
  min-height: 45px;
  padding: 8px 15px;
  font-size: 1.3rem;
  font-weight: 400;
  border-radius: 30px;
  border: 2px solid #000000;
  color: #eaeaea;
  background: #4d6cc3;
  line-height: 1;
  letter-spacing: 1.5px
}

.swapSection[_ngcontent-ng-c1685097014] .body-section[_ngcontent-ng-c1685097014] .amountField[_ngcontent-ng-c1685097014] .amountType[_ngcontent-ng-c1685097014] {
  position: absolute;
  top: 22px;
  right: .5rem;
  transform: translateY(-50%);
  display: flex;
  align-items: center
}

.swapSection[_ngcontent-ng-c1685097014] .body-section[_ngcontent-ng-c1685097014] .lowBalance[_ngcontent-ng-c1685097014] {
  line-height: 20px;
  font-weight: 400
}

.swapSection[_ngcontent-ng-c1685097014] .body-section[_ngcontent-ng-c1685097014] .title[_ngcontent-ng-c1685097014] {
  height: 40px
}

.swapSection[_ngcontent-ng-c1685097014] .body-section[_ngcontent-ng-c1685097014] .title[_ngcontent-ng-c1685097014] .text[_ngcontent-ng-c1685097014] {
  font-weight: 200
}

.swapSection[_ngcontent-ng-c1685097014] .body-section[_ngcontent-ng-c1685097014] .statusIcon[_ngcontent-ng-c1685097014] {
  height: 55px
}

.swapSection[_ngcontent-ng-c1685097014] .text[_ngcontent-ng-c1685097014] {
  background: none;
  padding: 5px 0;
  color: #fff;
  -webkit-text-stroke: 2px black;
  transform: unset
}

.swapSection[_ngcontent-ng-c1685097014] .btn-secondary[_ngcontent-ng-c1685097014] {
  min-height: 50px
}

.swapSection[_ngcontent-ng-c1685097014] .btn-secondary[_ngcontent-ng-c1685097014] .text[_ngcontent-ng-c1685097014] {
  transform: unset;
  width: 100% !important;
  padding: 6px 0;
  color: #fff;
  -webkit-text-stroke: 3px black
}