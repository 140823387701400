@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.cdnfonts.com/css/accelerator-2');

@tailwind base;
@tailwind components;
@tailwind utilities;

*{
  object-fit: cover;
  font-family: Lato;
}
@keyframes fadeInOut {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

.fade {
  animation: fadeInOut 2s infinite;
}

.acc{
  font-family: 'Accelerator', sans-serif;
}

.cardBox {
  border-radius: 14px;
  box-shadow: 0 2px 10px 0 #0006, 0 2px 25px 0 #00000080;
  display: grid;
  height: 50px;
  margin-bottom: 30px;
  overflow: hidden;
  place-items: center;
  position: relative;
  width: 176px;
}

.cardBox:before {
  animation: spin 3s linear infinite;
  background: linear-gradient(271.68deg, #f0b90b 50%, #e8749e 0);
  border-radius: 14px;
  content: "";
  height: 50px;
  left: -53px;
  position: absolute;
  top: -3px;
  width: 160%;
  z-index: 1;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}

.css-1bkblt6 {
  padding-bottom: 40px;
  padding-left: 0px;
  padding-right: 0px;
  background: linear-gradient(152.97deg, rgb(65, 66, 71) 0%, rgb(27, 29, 35) 100%);
  border-radius: 18px;
  text-align: center;
  border: 1px solid rgb(44, 45, 50);
}

.css-1yab1yo {
  background: radial-gradient(60% 51.57% at 50% 50%, rgb(240, 185, 11) 0%, rgba(232, 116, 158, 0.5) 100%);
  width: 100%;
  border-radius: 20px;
  padding: 4px;
}

.css-1q31rqf > .MuiGrid-item {
  padding-left: 24px;
}

.css-1q31rqf > .MuiGrid-item {
  padding-top: 24px;
}
.css-qo4kbb {
  box-sizing: border-box;
  margin: 0px;
  flex-direction: row;
  flex-basis: 100%;
  -webkit-box-flex: 0;
  flex-grow: 0;
  max-width: 100%;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  display: flex;
}

::before, ::after {
  box-sizing: inherit;
}

a:-webkit-any-link {
  color: -webkit-link;
  cursor: pointer;
}

.card-animation, .card-content {
  align-items: center;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  position: absolute;
}

.card-animation {
  background: linear-gradient(64.83deg, #30304e 15%, #27283c 60%);
  box-shadow: inset 0 30px 60px -12px #0006, inset 0 18px 36px -18px #00000080;
  color: #fff;
  cursor: pointer;
  flex-direction: column;
  height: auto;
  padding: 20px;
  text-align: center;
  width: 170px;
  z-index: 2;
}

.card-content {
  background: radial-gradient(circle at 10% 20%, #455666 0, #222 90%);
  font-size: 18px;
  height: 45px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.card-animation, .card-content {
  align-items: center;
  border-radius: 14px;
  display: flex;
  justify-content: center;
  position: absolute;
}

.zed{
  z-index: 99 !important;
}

.App{
  background: #1b1c22;
  background-image: url('../src/assets/bggg.png');
  /* background-repeat: no-repeat; */
  background-size: contain;
  /* background-position: left; */
}

.dots{
  background-image: url('https://multi-swiftcoin.web.app/assets/features-illustration-02.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
  
}

/* .banner{
  background: url('./asset/line.ad93247a.png');
} */

.specCard{
  background-image: linear-gradient(to bottom, #6969693b 0%, #15153b0a  60%);
  /* backdrop-filter: blur(7px) !important; */
  cursor: pointer;
  transition: 3s ease-in-out;
}


.specCard:hover{
  /* background-image: linear-gradient(to left, #ff00cc67 0%, #333399  51%, #ff00ccd0  100%); */

  background-image: linear-gradient(to bottom, #aaa 0%, #154860  90%);

  /* background-image: linear-gradient(to left, #2e013a 0%, #224a64  51%, #2e013a  100%); */
}

.grad{
  background-image: linear-gradient(to bottom, #aaa 0%, #154860  90%);
}


.btn-grad {
  /* background-image: linear-gradient(to left, #ff00cc67 0%, #333399  51%, #ff00ccd0  100%); */
  background-image: linear-gradient(to left, #666 0%, #154860  90%, #072538  100%);
  /* background-image: linear-gradient(to left, #e6bfde67 0%, #160068  51%, #4f29f7  100%); */
  
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  /* box-shadow: 0 0 20px #5a5a5a; */
  border: none;
}

.btn-grad:hover {
  background-position: top; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}

.textGrad{
    background: -webkit-linear-gradient(#ffffff, #86b3d1);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  
}



